import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { sumBy, map } from "lodash-es";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useEditing from "./useEditing";
import useStore, { SelectableItem, UserSelection } from "utils/useStore";

import Modal from "components/Modal";
import useCoffins from "views/coffin/useCoffins";

const Coffin = () => {
  const t = usePhase("summary");
  const storedCoffin = useStore((state) => state.coffin);
  const storedCoffinOptions = useStore((state) => state.coffinOptions);
  const calculateCoffinCost = useStore((state) => state.calculateCoffinCost);
  const setUserSelection = useStore((state) => state.setUserSelection);

  const [coffins, setOpenModalIndex, openModalIndex, ModalContent] =
    useCoffins();

  const closeModal = () => setOpenModalIndex(null);

  const storedCoffinIndex =
    storedCoffin?.id && coffins
      ? coffins.findIndex(({ id }) => storedCoffin.id === id)
      : 0;

  const [isEditing, Edit, ConfirmCancel] = useEditing();

  const [coffin, setCoffin] = useState<UserSelection>(null);

  const [coffinOptions, setCoffinOptions] = useState<SelectableItem[] | null>(
    null
  );

  const coffinIndex =
    coffin && coffins ? coffins.findIndex(({ id }) => +coffin.id === id) : 0;

  const confirm = () => {
    if (coffin) {
      setUserSelection("coffin", coffin);
    }

    if (coffinOptions) {
      setUserSelection("coffinOptions", coffinOptions);
    }

    if (!coffinOptions) {
      setUserSelection("coffinOptions", null);
    }
  };

  let coffinOptionLabel = map(storedCoffinOptions, "title").join(", ");
  let coffinOptionPrice = sumBy(storedCoffinOptions, "price");

  if (coffinOptions !== null) {
    coffinOptionLabel = map(coffinOptions, "title").join(", ");
    coffinOptionPrice = sumBy(coffinOptions, "price");
  }

  useEffect(() => {
    if (!isEditing) {
      setCoffin(null);
      setCoffinOptions(null);
    }
  }, [isEditing]);

  return (
    <div className={styles.block}>
      <div className={styles.subtitle}>
        <h3 className="h3">{t("coffin")}</h3>
        <span className={styles.price}>{calculateCoffinCost()} €</span>
        <Edit />
      </div>
      {!isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("coffin")}</div>
            <div className={styles.value}>
              {storedCoffin?.title}
              <div className={styles.partprice}>{storedCoffin?.price} €</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("services")}</div>
            <div className={styles.value}>
              <div>{coffinOptionLabel}</div>
              <div className={styles.partprice}>{coffinOptionPrice} €</div>
            </div>
          </div>
        </div>
      )}
      {isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("coffin")}</div>
            <div
              onClick={() =>
                setOpenModalIndex(coffin ? coffinIndex : storedCoffinIndex)
              }
              className={styles.editingValue}
            >
              {coffin ? coffin.title : storedCoffin?.title}
              <div className={styles.partprice}>
                {coffin ? coffin.price : storedCoffin?.price} €
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("services")}</div>
            <div className={styles.value}>
              <div>{coffinOptionLabel}</div>
              <div className={styles.partprice}>{coffinOptionPrice} €</div>
            </div>
          </div>
        </div>
      )}

      <AnimatePresence mode='wait'>
        {openModalIndex !== null && (
          <Modal closeModal={closeModal} childKey={`modal-${openModalIndex}`}>
            <ModalContent
              closeModal={closeModal}
              setCoffin={setCoffin}
              setCoffinOptions={setCoffinOptions}
              selected={coffinOptions}
            />
          </Modal>
        )}
      </AnimatePresence>
      <ConfirmCancel confirm={confirm} />
    </div>
  );
};

export default Coffin;
