import React, { useState } from "react";
import { UserSelection } from "utils/useStore";

import PackModalContent from "./PackModalContent";
import useMappedUrnPrices from "./useMappedUrnPrices";

const useUrns = () => {
  const urns = useMappedUrnPrices();

  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);

  const ModalContent = ({
    closeModal,
    setUrn,
    dontChangeState,
  }: {
    closeModal: React.MouseEventHandler<HTMLButtonElement>;
    setUrn: (urn: UserSelection) => void;
    dontChangeState?: boolean;
  }) => (
    <>
      {openModalIndex !== null && (
        <PackModalContent
          urn={urns[openModalIndex]}
          closeModal={closeModal}
          setUrn={setUrn}
          dontChangeState={dontChangeState}
          browse={(newDirection: 1 | -1) => {
            const newIndex = openModalIndex + newDirection;
            if (newIndex >= 0 && newIndex < urns.length) {
              setOpenModalIndex(newIndex);
            }
          }}
        />
      )}
    </>
  );

  return [urns, setOpenModalIndex, openModalIndex, ModalContent] as const;
};

export default useUrns;
