import getData from "utils/getData";
import parseEmbeddedImage from "utils/parseEmbeddedImage";
import { Rest } from "types/rest";

const getMessagesAndIcons = (
  callbackMessages: React.Dispatch<React.SetStateAction<Rest.Messages | undefined>>, 
  callbackIcons: React.Dispatch<React.SetStateAction<(string | null)[] | undefined>>
) => {
  getData((result: any) => {
    if (!Array.isArray(result)) {
      return callbackMessages(undefined);
    }

    const [messages, icons] = result.reduce(
      (acc, current) => {
        if (
          !current.acf ||
          !current.acf.phase_key ||
          !current.acf.phase_chat_texts
        ) {
          return acc;
        }

        const key = current.acf.phase_key;
        const texts = current.acf.phase_chat_texts || [];

        if (!key || !Array.isArray(texts)) {
          return acc;
        }

        acc[0][key] = texts.reduce((textAcc, item) => {
          if (item.key && item.text) {
            textAcc[item.key] = item.text;
          }

          return textAcc;
        }, {});

        const image = parseEmbeddedImage(current, "full");

        acc[1][key] = image ? image.src : null;

        return acc;
      },
      [{}, {}]
    );

    callbackMessages(() => messages);
    callbackIcons(() => icons);
  }, "phase/?_embed&per_page=99");
};

export default getMessagesAndIcons;
