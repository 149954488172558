import React from "react";

import Modal from "components/Modal";
import Button, { Buttons } from "components/Button";
import ExternalLinkSymbol from "components/ExternalLinkSymbol";

import usePhase from "utils/usePhase";
import useAgency from "utils/useAgency";

import styles from "./Header.module.css";

import constants from "constants/fi.json";
import BodyText from "./BodyText";

interface Props {
  dark?: boolean;
  closeModal: React.MouseEventHandler<HTMLDivElement>;
}

const FooterContent = ({ title, text }: { title: string; text: string }) => (
  <>
    <h3 className="h3">{title}</h3>
    <p>{text}</p>
    <a
      href="https://memoria.fi"
      target="_blank"
      title={constants.move_to_memoria_fi}
      rel="noreferrer"
    >
      <Button fullWidth secondary id="button-memoria-fi">
        {constants.move_to_memoria_fi} <ExternalLinkSymbol />
      </Button>
    </a>
  </>
);

const InfoModal = ({ closeModal }: Props) => {
  const t = usePhase("infomodal");

  const [agencyEmail, agencyPhone, agencyTitle] = useAgency([
    "acf.agency_email",
    "acf.agency_phone",
    "title.rendered",
  ]);

  const phone = agencyPhone ? String(agencyPhone) : t("default_phone");
  const email = agencyEmail ? String(agencyEmail) : t("default_email");

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.infomodal}>
        <h2 className="h2">{t("title")}</h2>
        <BodyText fullWidth>
          {agencyTitle && (
            <>
              <b>{agencyTitle}</b> {t("paragraph1")}
            </>
          )}
          {!agencyTitle && t("default_paragraph")}
        </BodyText>
        <Buttons fullWidth stack>
          {phone && (
            <a href={`tel:${phone}`} id="button-phone">
              <Button>{[constants.call_us, phone].join(" ")}</Button>
            </a>
          )}
          {email && (
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
              id="button-email"
            >
              <Button>{constants.email_us}</Button>
            </a>
          )}
        </Buttons>
        <div className={styles.infofooter}>
          <FooterContent title={t("footer_title")} text={t("footer_text")} />
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
