import React, { useState } from "react";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useEditing from "./useEditing";
import useStore from "utils/useStore";
import Dropdown from "components/Dropdown";

import constants from "constants/fi.json";
import Modal from "components/Modal";
import Button, { Buttons } from "components/Button";
import { Link } from "react-router-dom";

const Transportation = () => {
  const t = usePhase("summary");
  const setUserSelection = useStore((state) => state.setUserSelection);
  const transportationCost = useStore((state) => state.transportationCost);
  const currentMunicipality = useStore((state) => state.currentMunicipality);
  const hospital = useStore((state) => state.hospital);
  const targetMunicipality = useStore((state) => state.targetMunicipality);
  const blessingPlace = useStore((state) => state.blessingPlace);
  const attendTransport = useStore((state) => state.attendTransport);
  const [_attendTransport, _setAttendTransport] = useState<boolean | null>(
    null
  );

  const hospitalTitle = hospital?.title || constants.i_dont_know;

  const attendTransportOptions = [
    {
      id: 1,
      title: constants.yes
    },
    {
      id: 0,
      title: constants.no_thanks
    }
  ];

  const [isEditing, Edit, ConfirmCancel, modalOpen, UnEditable, closeModal] =
    useEditing();

  const confirm = () => {
    if (_attendTransport !== null) {
      setUserSelection("attendTransport", _attendTransport);
    }
  };

  return (
    <div className={styles.block}>
      <div className={styles.subtitle}>
        <h3 className="h3">{t("transportation")}</h3>
        <span className={styles.price}>{transportationCost} €</span>
        <Edit />
      </div>
      {!isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("starting_point")}</div>
            <div className={styles.value}>{currentMunicipality?.title}</div>
            <div className={styles.value}>{hospitalTitle}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.key}>{t("destination")}</div>
            <div className={styles.value}>{targetMunicipality?.title}</div>
            <div className={styles.value}>{blessingPlace?.title}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.key}>{t("attend_transportation")}</div>
            <div className={styles.value}>
              {attendTransport === true ? constants.yes : constants.no}
            </div>
          </div>
        </div>
      )}
      {isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("starting_point")}</div>
            <UnEditable>{currentMunicipality?.title}</UnEditable>
            <UnEditable>{hospitalTitle}</UnEditable>
          </div>

          <div className={styles.row}>
            <div className={styles.key}>{t("destination")}</div>
            <UnEditable>{targetMunicipality?.title}</UnEditable>
            <UnEditable>{blessingPlace?.title}</UnEditable>
          </div>

          <div className={styles.row}>
            <div className={styles.key}>{t("attend_transportation")}</div>

            <Dropdown
              options={attendTransportOptions}
              onChange={(selected) =>
                _setAttendTransport(selected.title === constants.yes)
              }
              value={attendTransport ? constants.yes : constants.no_thanks}
              summary
            />
          </div>
        </div>
      )}
      <ConfirmCancel confirm={confirm} />
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <span className="h2">Huom!</span>
          <p className="paragraph">
            Nämä valinnat vaikuttavat kaikkeen. Jos haluat muokata, joudut
            palaamaan alkuun.
          </p>
          <Buttons insideModal>
            <Link to="/kuljetukset/">
              <Button tiny secondary>
                Aloita alusta
              </Button>
            </Link>
            <Button tiny onClick={closeModal}>
              Peruuta
            </Button>
          </Buttons>
        </Modal>
      )}
    </div>
  );
};

export default Transportation;
