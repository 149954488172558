import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Button from "components/Button";

import styles from "./Header.module.css";

import constants from "constants/fi.json";
import InfoModal from "./InfoModal";

interface Props {
  cover?: boolean;
  progress: number;
}

interface InfoProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const useMetaThemeColor = (cover?: boolean) => {
  useEffect(() => {
    document
      .querySelector("meta[name='theme-color']")
      ?.setAttribute("content", cover ? "#1e1a34" : "#ebe7e2");
  }, [cover]);
};

const InfoButton = ({ onClick }: InfoProps) => (
  <div className={styles.infobutton}>
    <Button onClick={onClick} header>
      {constants.open_info_modal}
    </Button>
  </div>
);

const Logo = ({ cover }: { cover?: boolean }) => {
  const fill = "var(--light-purple)";

  const animate = {
    fill: cover ? "var(--gray-beige)" : "var(--light-purple)",
  };

  return (
    <svg
      className={styles.logo}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 137 32"
    >
      <defs>
        <path id="a" d="M0 32h136.266V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <motion.path
          fill={fill}
          animate={animate}
          initial={animate}
          transition={{ delay: 1 }}
          d="m53.686 20.531-3.382 7.7-3.399-7.7h-1.868L43.49 31.807h2.256l.901-6.878 3.045 6.878h1.175l3.029-6.846.902 6.846h2.271l-1.546-11.276zM61.561 20.531v11.276h6.958v-2.094h-4.703v-2.658h4.381v-2.093h-4.381v-2.337h4.542v-2.094zM82.779 20.531l-3.383 7.7-3.399-7.7h-1.868l-1.546 11.276h2.255l.902-6.878 3.044 6.878h1.176l3.028-6.846.902 6.846h2.271l-1.546-11.276z"
        />
        <g>
          <motion.path
            d="M97.7 29.423a3.452 3.452 0 0 0 1.313-1.337c.316-.569.475-1.208.475-1.917 0-.708-.159-1.347-.475-1.917a3.455 3.455 0 0 0-1.313-1.337 3.675 3.675 0 0 0-1.869-.482c-.687 0-1.31.16-1.868.482a3.456 3.456 0 0 0-1.313 1.337 3.881 3.881 0 0 0-.475 1.917c0 .71.158 1.348.475 1.917.317.57.754 1.015 1.313 1.337a3.672 3.672 0 0 0 1.868.483c.687 0 1.31-.16 1.869-.483m-4.856 1.82a5.759 5.759 0 0 1-2.167-2.086c-.537-.886-.805-1.881-.805-2.988 0-1.106.268-2.102.805-2.988a5.77 5.77 0 0 1 2.167-2.086 6.05 6.05 0 0 1 2.987-.756c1.085 0 2.08.252 2.988.756.908.505 1.63 1.201 2.167 2.086.536.886.805 1.882.805 2.988 0 1.107-.269 2.102-.805 2.988a5.766 5.766 0 0 1-2.167 2.086 6.034 6.034 0 0 1-2.988.757 6.032 6.032 0 0 1-2.987-.757M108.453 22.626v3.092h1.498c.612 0 1.084-.144 1.417-.434.333-.29.5-.66.5-1.112 0-.45-.167-.822-.5-1.112-.333-.29-.805-.434-1.417-.434h-1.498Zm4.284 9.181-3.302-3.995h-.982v3.995h-2.255V20.532h3.769c.87 0 1.62.155 2.255.467.633.311 1.116.741 1.45 1.289.332.547.499 1.175.499 1.884 0 .763-.194 1.431-.58 2.005-.387.575-.934 1.008-1.643 1.297l3.592 4.333h-2.803Z"
            fill={fill}
            animate={animate}
            initial={animate}
            transition={{ delay: 1 }}
          />
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <motion.path
            fill={fill}
            animate={animate}
            initial={animate}
            transition={{ delay: 1 }}
            mask="url(#b)"
            d="M119.19 31.807h2.255V20.532h-2.255zM132.304 27.362l-1.546-4.172-1.547 4.172h3.093Zm3.962 4.445h-2.416l-.853-2.351h-4.51l-.854 2.351h-2.384l4.397-11.275h2.223l4.397 11.275ZM28.86 30.304H20V16.666c0-4.543-3.046-8.386-7.202-9.6 1.189-3.133 4.213-5.37 7.758-5.37 4.579 0 8.304 3.725 8.304 8.304v20.304Zm-10.557 0h-6.05V10c0-.44.044-.87.11-1.29 3.43 1.02 5.94 4.198 5.94 7.956v13.638ZM1.696 16.666c0-4.58 3.725-8.304 8.304-8.304.232 0 .46.016.687.035-.085.522-.13 1.057-.13 1.603v20.304h-8.86V16.666ZM20.556 0c-4.368 0-8.089 2.817-9.447 6.729-.364-.04-.734-.064-1.11-.064-5.513 0-9.999 4.486-9.999 10V32h30.556V10c0-5.514-4.486-10-10-10Z"
          />
        </g>
      </g>
    </svg>
  );
};

const Header = ({ cover, progress }: Props) => {
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);

  useMetaThemeColor(cover);

  const background = cover ? "var(--dark-purple)" : "var(--light-beige)";
  const x = `${progress - 100}%`;

  return (
    <>
      <AnimatePresence>
        {infoModalOpen && (
          <InfoModal closeModal={() => setInfoModalOpen(false)} />
        )}
      </AnimatePresence>

      <motion.header
        className={styles.header}
        animate={{ background }}
        transition={{ delay: 1 }}
      >
        <Logo cover={cover} />
        <InfoButton onClick={() => setInfoModalOpen(true)} />
      </motion.header>

      <motion.section
        className={styles.progress}
        animate={{
          background: cover ? "var(--light-purple)" : "var(--gray-beige)",
        }}
        transition={{ delay: 1 }}
      >
        <motion.div
          className={styles.indicator}
          initial={{ x }}
          animate={{
            x,
            background: cover ? "var(--light-beige)" : "var(--dark-purple)",
          }}
          exit={{ x }}
          transition={{ ease: "easeOut", duration: 0.66, delay: 1.05 }}
          key="progress"
        />
      </motion.section>
    </>
  );
};

export default Header;
