// @ts-nocheck

const pushSelection = (data: any) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "selectionEvent",
    ...data,
  });
};

const pushVirtualPageview = (data: any) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "virtualPageview",
    ...data,
  });
};

const analytics = {
  pushSelection,
  pushVirtualPageview,
};

export default analytics;
