import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Button, { Buttons } from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";
import Dropdown from "components/Dropdown";
import Bottom from "components/Bottom";
import Previous from "components/Previous";

import { useData } from "utils/getData";
import useStore from "utils/useStore";
import usePhase from "utils/usePhase";
import analytics from "utils/analytics";

import { FadeUp } from "animations";

import constants from "constants/fi.json";

import SkippableDropdown from "./SkippableDropdown";
import useMunicipalityLanguageCase from "./useMunicipalityLanguageCase";
import useOrderAgency from "./useOrderAgency";
import useTransportationCost from "./useTransportationCost";
import useTransportationStore from "./useTransportationStore";
import useAgency from "utils/useAgency";

const Transport = () => {
  const municipalities = useData("municipality?per_page=987") as any;
  const t = usePhase("transport");
  const casedMunicipality = useMunicipalityLanguageCase(municipalities);

  const mappedMunicipalities = Array.isArray(municipalities)
    ? municipalities
        .map(({ id, name }) => {
          return { id: +id, title: name };
        })
        .sort((a, b) => a.title.localeCompare(b.title, "fi"))
    : [];

  const [
    hospital,
    setHospital,
    shadowHospital,
    setShadowHospital,
    currentMunicipality,
    setCurrentMunicipality,
    targetMunicipality,
    setTargetMunicipality,
    blessingPlace,
    setBlessingPlace,
    skipBlessingPlace,
    setEmptyBlessingPlace,
    attendTransport,
    setAttendTransport,
    undo,
  ] = useTransportationStore();

  const hospitalSelected = !!(hospital || shadowHospital);

  const setUserSelection = useStore((state) => state.setUserSelection);

  const [noAgencyAvailable] = useOrderAgency(targetMunicipality?.id);

  const [cost, transportDisallowed] = useTransportationCost(
    hospital || shadowHospital,
    blessingPlace,
    targetMunicipality,
    skipBlessingPlace
  );

  const [agencyPhone, agencyTitle] = useAgency([
    "acf.agency_phone",
    "title.rendered",
  ]) as any;

  useEffect(() => {
    // Reset rest of the info when currentMunicipality changes
    if (currentMunicipality !== null) {
      setUserSelection("targetMunicipality", null);
      setUserSelection("hospital", null);
      setUserSelection("blessingPlace", null);
      setUserSelection("transportationCost", 0);
    }
  }, [currentMunicipality, setUserSelection]);

  useEffect(() => {
    if (currentMunicipality !== null) {
      analytics.pushSelection({
        selectionTitle: "Current municipality",
        selectionValue: currentMunicipality.title,
      });
    }
  }, [currentMunicipality]);

  useEffect(() => {
    if (targetMunicipality !== null) {
      analytics.pushSelection({
        selectionTitle: "Target municipality",
        selectionValue: targetMunicipality.title,
      });
    }
  }, [targetMunicipality]);

  useEffect(() => {
    if (hospital !== null) {
      analytics.pushSelection({
        selectionTitle: "Hospital",
        selectionValue: hospital.title,
      });
    }
  }, [hospital]);

  useEffect(() => {
    if (blessingPlace !== null) {
      analytics.pushSelection({
        selectionTitle: "Blessing place",
        selectionValue: blessingPlace.title,
      });
    }
  }, [blessingPlace]);

  useEffect(() => {
    if (attendTransport !== null) {
      setUserSelection("attendTransport", attendTransport);
    }

    if (currentMunicipality !== null) {
      setUserSelection("currentMunicipality", currentMunicipality);
    }

    if (targetMunicipality !== null) {
      setUserSelection("targetMunicipality", targetMunicipality);
    }

    if (hospital !== null) {
      setUserSelection("hospital", hospital);
    }

    if (blessingPlace !== null) {
      setUserSelection("blessingPlace", blessingPlace);
    }

    if (cost !== null) {
      setUserSelection("transportationCost", parseInt(cost));
      setUserSelection("transportationOutOfBounds", false);
    }

    if (transportDisallowed === true) {
      setUserSelection("transportationOutOfBounds", true);
      setUserSelection("transportationCost", 0);
    }
  });

  return (
    <>
      <div className="App-content">
        <Bubble disableAutomaticScroll>{t("where_body")}</Bubble>

        {currentMunicipality && hospitalSelected && (
          <>
            <UserBubble>
              {t(
                "body_location_is_a",
                "%municipality%",
                casedMunicipality(currentMunicipality.id, "in")
              )}
              {hospital
                ? t("body_location_is_b", "%location%", hospital.title)
                : t("body_location_is_i_dont_know")}
            </UserBubble>
            <Bubble delay={0.75}>{t("thanks_where_to_transport")}</Bubble>
          </>
        )}
        {targetMunicipality && blessingPlace && (
          <>
            <UserBubble>
              {t(
                "body_location_to_a",
                "%municipality%",
                casedMunicipality(targetMunicipality.id, "to")
              )}
              {skipBlessingPlace && t("body_location_to_i_dont_know")}
              {!skipBlessingPlace &&
                t("body_location_to_b", "%location%", blessingPlace.title)}
            </UserBubble>

            {agencyTitle && agencyPhone && (
              <UserBubble>
                {t("serving_agency", "%serving_agency%", agencyTitle)}
                <a href={`tel:${agencyPhone}`}>
                  {t(
                    "serving_agency_phone",
                    "%serving_agency_phone%",
                    agencyPhone
                  )}
                </a>
              </UserBubble>
            )}

            {skipBlessingPlace && (
              <Bubble disableAutomaticScroll>
                {t("skipping_blessing_place_message")}
              </Bubble>
            )}
            <Bubble disableAutomaticScroll>
              {t("thanks_transport_summary")}
            </Bubble>
            {cost && (
              <>
                <Bubble disableAutomaticScroll>
                  {t("summary_of_transport")}
                  <br />
                  <br />
                  {t("price", "%price%", cost)}
                </Bubble>
                <Bubble disableAutomaticScroll>
                  {t("how_many_participate")}
                </Bubble>
              </>
            )}
            {transportDisallowed && (
              <Bubble delay={1.5}>{t("cant_transport")}</Bubble>
            )}
            {noAgencyAvailable && (
              <Bubble delay={1.5}>{t("no_agency_found")}</Bubble>
            )}
            {attendTransport === true && (
              <UserBubble>{t("participate_posivive")}</UserBubble>
            )}
            {attendTransport === false && (
              <UserBubble>{t("participate_negative")}</UserBubble>
            )}
            {attendTransport !== null && (
              <Bubble delay={0.75}>{t("thanks_next_burial")}</Bubble>
            )}
          </>
        )}
      </div>
      <Bottom>
        <AnimatePresence mode='wait'>
          {!currentMunicipality && !hospitalSelected && (
            <FadeUp key="transport1">
              <Dropdown
                options={mappedMunicipalities}
                onChange={(selected) => {
                  setHospital(null);
                  setCurrentMunicipality(selected);
                }}
                placeholder={t("select_municipality")}
                filled={!!currentMunicipality}
                search
              />
            </FadeUp>
          )}
          {currentMunicipality && !hospitalSelected && (
            <FadeUp key="transport2">
              <SkippableDropdown
                path="hospital"
                select={setHospital}
                municipality={currentMunicipality}
                selectShadow={setShadowHospital}
              />
            </FadeUp>
          )}

          {hospitalSelected && !blessingPlace && !targetMunicipality && (
            <FadeUp key="transport3">
              <Dropdown
                options={mappedMunicipalities}
                onChange={(selected) => {
                  setTargetMunicipality(selected);
                  setBlessingPlace(null);
                }}
                placeholder={t("select_municipality")}
                filled={!!targetMunicipality}
                search
              />
            </FadeUp>
          )}
          {hospitalSelected && !blessingPlace && targetMunicipality && (
            <FadeUp key="transport4">
              <SkippableDropdown
                path="blessing-place"
                select={setBlessingPlace}
                municipality={targetMunicipality}
                onSkip={setEmptyBlessingPlace}
              />
            </FadeUp>
          )}

          {!noAgencyAvailable &&
            blessingPlace &&
            attendTransport === null &&
            !transportDisallowed && (
              <FadeUp key="transport5">
                <Buttons>
                  <FadeUp delay={0.5}>
                    <Button
                      onClick={() => {
                        setAttendTransport(true);
                      }}
                    >
                      {constants.yes}
                    </Button>
                  </FadeUp>
                  <FadeUp delay={0.75}>
                    <Button
                      onClick={() => {
                        setAttendTransport(false);
                      }}
                    >
                      {constants.no_thanks}
                    </Button>
                  </FadeUp>
                </Buttons>
              </FadeUp>
            )}

          {(attendTransport !== null || transportDisallowed) && (
            <FadeUp key="transport6">
              <Link to="/hautaustapa">
                <Button>{constants.next}</Button>
              </Link>
            </FadeUp>
          )}
        </AnimatePresence>
        <Previous to={undo("/kuljetukset")} />
      </Bottom>
    </>
  );
};

export default Transport;
