import { useNavigate } from "react-router-dom";
import useStore, { UserSelection } from "utils/useStore";

const useCoffinStore = () => {
  const setUserSelection = useStore((state) => state.setUserSelection);

  const coffin = useStore((state) => state.coffin);
  const setCoffin = (coffin: UserSelection) =>
    setUserSelection("coffin", coffin);

  const coffinOptions = useStore((state) => state.coffinOptions);
  const setCoffinOptions = (coffinOptions: UserSelection[] | null) =>
    setUserSelection("coffinOptions", coffinOptions);

  const navigate = useNavigate();

  const undo = (previousPath: string) => () => {
    if (coffin) {
      setUserSelection("coffin", null);
      setUserSelection("coffinOptions", null);
    } else if (previousPath) {
      navigate(previousPath);
    }
  };

  return [coffin, setCoffin, coffinOptions, setCoffinOptions, undo] as const;
};

export default useCoffinStore;
