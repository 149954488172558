import create from "zustand";
import { persist } from "zustand/middleware";

import { Rest } from "types/rest";
import { WP_REST_API_Post } from "types/WP";

const CREMATION_ID = 313;

export type SelectableItem = {
  id: number;
  title: string;
  price?: string;
};

export type UserSelection = SelectableItem | null;

interface PayloadProps {
  agencies: WP_REST_API_Post[] | [];
  setAgenices: (agencies: WP_REST_API_Post[]) => void;
  setUserSelection: (
    key: string,
    value: UserSelection | number | string | boolean | UserSelection[]
  ) => void;
  phases: Rest.Messages | null;
  setPhases: (phases: Rest.Messages) => void;
  icons: (string | null)[] | null;
  setIcons: (icons: (string | null)[]) => void;
  setAgency: (agency: number | null) => void;
  setAgencyIsForced: (agencyIsForced: boolean) => void;

  agency: number | null;
  agencyIsForced: boolean;

  transportationCost: number | null;
  hospital: UserSelection;
  shadowHospital: UserSelection;
  currentMunicipality: UserSelection;
  blessingPlace: UserSelection;
  skipBlessingPlace: boolean | null;
  targetMunicipality: UserSelection;
  attendTransport: boolean | null;
  transportationOutOfBounds: boolean | null;

  burial: UserSelection;

  package: UserSelection;

  coffin: UserSelection;
  coffinOptions: SelectableItem[] | null;

  urn: UserSelection;
  recommendedUrns: number[] | null;

  clothing: UserSelection;

  coffinFlower: UserSelection;
  guestFlower: UserSelection;
  coffinFlowerSizes: UserSelection;

  urnFlower: UserSelection;

  whoReserves: string | null;
  eventType: UserSelection;
  datetime: string | null;

  burialPlace: string | null;
  cemetery: UserSelection;
  burialWishes: string | null;
  stoneOffer: UserSelection[] | null;
  servicesOffer: UserSelection[] | null;
  oldPlaceInfo: string | null;
  moreInfoAboutBurial: string | null;

  skipDeceasedInfo: "yes" | "no" | null,

  deceasedName: string | null;
  deceasedSocialId: string | null;
  deceasedInChurch:  "yes" | "no" | "unknown" | null;
  deceasedChurch: UserSelection;
  deceasedWishes: string | null;

  ordererName: string | null;
  ordererPhone: string | null;
  ordererEmail: string | null;
  ordererRelationship: string | null;

  calculateCost: () => number;
  calculateFlowerCost: () => number;
  calculateCoffinCost: () => number;

  needsUrnSelection: () => boolean;

  acceptCookies: boolean;
  cookiesReacted: boolean;

  enableCookies: () => void;
  disableCookies: () => void;

  resetUserSelections: () => void;
  setFromFetch: (data: any) => void;
}

const initial = {
  agencies: [],
  phases: null,
  icons: null,

  agency: null,
  agencyIsForced: false,

  transportationCost: null,
  currentMunicipality: null,
  blessingPlace: null,
  skipBlessingPlace: null,
  targetMunicipality: null,
  hospital: null,
  shadowHospital: null,
  attendTransport: null,
  transportationOutOfBounds: null,

  burial: null,

  package: null,

  coffin: null,
  coffinOptions: null,

  urn: null,
  recommendedUrns: null,

  clothing: null,

  coffinFlower: null,
  guestFlower: null,
  urnFlower: null,

  coffinFlowerSizes: null,

  whoReserves: null,
  eventType: null,
  datetime: null,

  burialPlace: null,
  cemetery: null,
  burialWishes: null,
  stoneOffer: null,
  servicesOffer: null,
  oldPlaceInfo: null,
  moreInfoAboutBurial: null,

  skipDeceasedInfo: null,

  deceasedName: null,
  deceasedSocialId: null,
  deceasedInChurch: null,
  deceasedChurch: null,
  deceasedWishes: null,

  ordererName: null,
  ordererPhone: null,
  ordererEmail: null,
  ordererRelationship: null,

  acceptCookies: false,
  cookiesReacted: false,
};

const initialForUser = {
  agency: null,
  agencyIsForced: false,

  transportationCost: null,
  currentMunicipality: null,
  blessingPlace: null,
  skipBlessingPlace: null,
  targetMunicipality: null,
  hospital: null,
  shadowHospital: null,
  attendTransport: null,
  transportationOutOfBounds: null,

  burial: null,

  package: null,

  coffin: null,
  coffinOptions: null,

  urn: null,
  recommendedUrns: null,

  clothing: null,

  coffinFlower: null,
  guestFlower: null,
  urnFlower: null,

  coffinFlowerSizes: null,

  whoReserves: null,
  eventType: null,
  datetime: null,

  burialPlace: null,
  cemetery: null,
  burialWishes: null,
  stoneOffer: null,
  servicesOffer: null,
  oldPlaceInfo: null,
  moreInfoAboutBurial: null,

  skipDeceasedInfo: null,

  deceasedName: null,
  deceasedSocialId: null,
  deceasedInChurch: null,
  deceasedChurch: null,
  deceasedWishes: null,

  ordererName: null,
  ordererPhone: null,
  ordererEmail: null,
  ordererRelationship: null,
};

const useStore = create<PayloadProps>()(
  persist(
    (set, get) => ({
      setAgenices: (agencies: WP_REST_API_Post[]) => set(() => ({ agencies })),
      setPhases: (phases: Rest.Messages) => set(() => ({ phases })),
      setIcons: (icons: (string | null)[]) => set(() => ({ icons })),
      setAgency: (agency: number | null) => set(() => ({ agency })),
      setAgencyIsForced: (agencyIsForced: boolean) =>
        set(() => ({ agencyIsForced })),

      calculateFlowerCost: () => {
        const coffinFlower = get().coffinFlower;
        const guestFlower = get().guestFlower;
        const coffinFlowerSizes = get().coffinFlowerSizes;
        const urnFlower = get().urnFlower;

        let cost = 0;

        const coffinFlowerPrice = coffinFlowerSizes
          ? coffinFlowerSizes
          : coffinFlower;

        [guestFlower, coffinFlowerPrice, urnFlower].forEach((item) => {
          if (item && item.price) {
            cost += parseInt(item.price);
          }
        });

        return cost;
      },

      calculateCoffinCost: () => {
        const coffin = get().coffin;
        const coffinOptions = get().coffinOptions;

        let cost = 0;

        if (coffin && coffin.price) {
          cost += parseInt(coffin.price);
        }

        if (coffinOptions) {
          coffinOptions.forEach((item) => {
            if (item && item.price) {
              cost += parseInt(item.price);
            }
          });
        }

        return cost;
      },

      calculateCost: () => {
        const clothing = get().clothing;
        const coffin = get().coffin;
        const coffinFlower = get().coffinFlower;
        const coffinOptions = get().coffinOptions;
        const guestFlower = get().guestFlower;
        const coffinFlowerSizes = get().coffinFlowerSizes;
        const transportationCost = get().transportationCost;
        const pack = get().package;
        const urn = get().urn;
        const urnFlower = get().urnFlower;

        let cost = transportationCost || 0;

        if (pack && pack.price) {
          return parseInt(pack.price) + cost;
        }

        const coffinPrice = coffinFlowerSizes
          ? coffinFlowerSizes
          : coffinFlower;

        [clothing, coffin, guestFlower, coffinPrice, urn, urnFlower].forEach(
          (item) => {
            if (item && item.price) {
              cost += parseInt(item.price);
            }
          }
        );

        if (coffinOptions) {
          coffinOptions.forEach((item) => {
            if (item && item.price) {
              cost += parseInt(item.price);
            }
          });
        }

        return cost;
      },

      setUserSelection: (
        key: string,
        value: UserSelection | number | string | boolean | UserSelection[]
      ) => {
        set(() => {
          const result: any = {};
          result[key] = value;
          return result;
        });
      },

      needsUrnSelection: () => {
        const burial = get().burial;

        return Boolean(burial && burial.id === CREMATION_ID);
      },

      enableCookies: () =>
        set(() => ({ acceptCookies: true, cookiesReacted: true })),
      disableCookies: () =>
        set(() => ({ acceptCookies: false, cookiesReacted: true })),

      resetUserSelections: () => set(() => initialForUser),

      setFromFetch: (data: any) => {
        const {
          cost,
          agency,
          agencyIsForced,
          attendTransport,
          blessingPlace,
          burial,
          burialPlace,
          burialWishes,
          cemetery,
          clothing,
          coffin,
          coffinFlower,
          coffinFlowerSizes,
          coffinOptions,
          currentMunicipality,
          datetime,
          deceasedChurch,
          deceasedName,
          deceasedSocialId,
          deceasedWishes,
          guestFlower,
          eventType,
          hospital,
          moreInfoAboutBurial,
          oldPlaceInfo,
          ordererEmail,
          ordererName,
          ordererPhone,
          ordererRelationship,
          servicesOffer,
          stoneOffer,
          targetMunicipality,
          transportationCost,
          transportationOutOfBounds,
          urn,
          urnFlower,
          whoReserves
        } = data;

        set(() => ({
          cost,
          agency,
          agencyIsForced,
          attendTransport,
          blessingPlace,
          burial,
          burialPlace,
          burialWishes,
          cemetery,
          clothing,
          coffin,
          coffinFlower,
          coffinFlowerSizes,
          coffinOptions,
          currentMunicipality,
          datetime,
          deceasedChurch,
          deceasedName,
          deceasedSocialId,
          deceasedWishes,
          guestFlower,
          eventType,
          hospital,
          moreInfoAboutBurial,
          oldPlaceInfo,
          ordererEmail,
          ordererName,
          ordererPhone,
          ordererRelationship,
          package: data.package,
          servicesOffer,
          stoneOffer,
          targetMunicipality,
          transportationCost,
          transportationOutOfBounds,
          urn,
          urnFlower,
          whoReserves
        }));
      },

      ...initial,
    }),
    {
      name: "memoria-v1",
      getStorage: () => sessionStorage,
    }
  )
);

export default useStore;
