import React from "react";
import styles from "./BodyText.module.css";

interface Props {
  children: React.ReactNode;
  large?: boolean;
  small?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const BodyText = ({ children, large, small, fullWidth, className }: Props) => {
  const classes = [styles.bodyText];

  if (large) {
    classes.push(styles.large);
  }

  if (small) {
    classes.push(styles.small);
  }

  if (fullWidth) {
    classes.push(styles.fullWidth);
  }

  if (className) {
    classes.push(className);
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

export default BodyText;
