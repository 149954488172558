import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import usePhase from "utils/usePhase";
import useStore, { UserSelection } from "utils/useStore";

import Modal from "components/Modal";

import useGuestFlowers from "views/flowers/useGuestFlowers";
import useCoffinFlowers from "views/flowers/useCoffinFlowers";

import useEditing from "./useEditing";

import styles from "./summary.module.css";

const Flowers = () => {
  const t = usePhase("summary");
  const [isEditing, Edit, ConfirmCancel] = useEditing();
  const setUserSelection = useStore((state) => state.setUserSelection);

  const storedCoffinFlower = useStore((state) => state.coffinFlower);
  const storedGuestFlower = useStore((state) => state.guestFlower);
  const storedCoffinFlowerSizes = useStore((state) => state.coffinFlowerSizes);
  const calculateFlowerCost = useStore((state) => state.calculateFlowerCost);

  const storedCoffinFlowerPrice = storedCoffinFlowerSizes
    ? storedCoffinFlowerSizes.price
    : storedCoffinFlower?.price;

  const [
    guestFlowers,
    setGuestFlowerIndex,
    guestFlowerIndex,
    GuestFlowerModalContent,
  ] = useGuestFlowers();

  const [
    coffinFlowers,
    setCoffinFlowerIndex,
    coffinFlowerIndex,
    CoffinFlowerModalContent,
  ] = useCoffinFlowers();

  const closeCoffinModal = () => setCoffinFlowerIndex(null);
  const closeGuestModal = () => setGuestFlowerIndex(null);

  const [coffinFlower, setCoffinFlower] = useState<UserSelection>(null);

  const [guestFlower, setGuestFlower] = useState<UserSelection>(null);

  const [coffinFlowerSize, setCoffinFlowerSize] = useState<UserSelection>(null);

  const storedGuestFlowerIndex =
    storedGuestFlower?.id && guestFlowers
      ? guestFlowers.findIndex(({ id }) => storedGuestFlower.id === id)
      : 0;

  const storedCoffinFlowerIndex =
    storedCoffinFlower?.id && coffinFlowers
      ? coffinFlowers.findIndex(({ id }) => storedCoffinFlower.id === id)
      : 0;

  const confirm = () => {
    if (coffinFlower) {
      setUserSelection("coffinFlower", coffinFlower);
    }

    if (guestFlower) {
      setUserSelection("guestFlower", guestFlower);
    }

    if (coffinFlowerSize) {
      setUserSelection("coffinFlowerSizes", coffinFlowerSize);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      setCoffinFlower(null);
      setGuestFlower(null);
      setCoffinFlowerSize(null);
    }
  }, [isEditing]);

  return (
    <>
      <AnimatePresence mode='wait'>
        {coffinFlowerIndex !== null && (
          <Modal
            closeModal={closeCoffinModal}
            childKey={`modal-${coffinFlowerIndex}`}
          >
            <CoffinFlowerModalContent
              setCoffinFlower={setCoffinFlower}
              closeModal={closeCoffinModal}
              setCoffinFlowerSize={setCoffinFlowerSize}
              selectedSize={
                coffinFlowerSize !== null
                  ? coffinFlowerSize
                  : storedCoffinFlowerSizes
              }
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        {guestFlowerIndex !== null && (
          <Modal
            closeModal={closeGuestModal}
            childKey={`modal-${guestFlowerIndex}`}
          >
            <GuestFlowerModalContent
              setGuestFlower={setGuestFlower}
              closeModal={closeGuestModal}
              dontChangeState
            />
          </Modal>
        )}
      </AnimatePresence>

      <div className={styles.block}>
        <div className={styles.subtitle}>
          <h3 className="h3">{t("flowers")}</h3>
          <span className={styles.price}>{calculateFlowerCost()} €</span>
          <Edit />
        </div>
        {!isEditing && (
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.key}>{t("coffin_flower")}</div>
              <div className={styles.value}>
                {storedCoffinFlower?.title}
                <div className={styles.partprice}>
                  {storedCoffinFlowerPrice} €
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.key}>{t("guest_flower")}</div>
              <div className={styles.value}>
                {storedGuestFlower?.title}
                <div className={styles.partprice}>
                  {storedGuestFlower?.price} €
                </div>
              </div>
            </div>
          </div>
        )}
        {isEditing && (
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.key}>{t("coffin_flower")}</div>

              <div
                onClick={() => setCoffinFlowerIndex(storedCoffinFlowerIndex)}
                className={styles.editingValue}
              >
                {coffinFlower ? coffinFlower.title : storedCoffinFlower?.title}
                <div className={styles.partprice}>
                  {coffinFlowerSize
                    ? coffinFlowerSize.price
                    : storedCoffinFlowerPrice}{" "}
                  €
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.key}>{t("guest_flower")}</div>
              <div
                onClick={() => setGuestFlowerIndex(storedGuestFlowerIndex)}
                className={styles.editingValue}
              >
                {guestFlower ? guestFlower.title : storedGuestFlower?.title}
                <div className={styles.partprice}>
                  {guestFlower ? guestFlower.price : storedGuestFlower?.price} €
                </div>
              </div>
            </div>
          </div>
        )}
        <ConfirmCancel confirm={confirm} />
      </div>
    </>
  );
};

export default Flowers;
