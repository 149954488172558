import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./Modal.module.css";

interface Props {
  children: React.ReactNode;
  closeModal: React.MouseEventHandler<HTMLDivElement>;
  childKey?: string;
}

const variants = {
  content: {
    hidden: {
      opacity: 0,
      y: 25,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.25,
      },
    },
    exit: {
      opacity: 0,
      y: 0,
    },
  },
  background: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 0.6,
      transition: {
        duration: 0.25,
      },
    },
    exit: {
      opacity: 0,
    },
  },
  h: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.25,
      },
    },
    exit: {
      opacity: 0,
    },
  },
};

const Background = ({ closeModal }: Pick<Props, "closeModal">) => (
  <motion.div
    key="modal-background"
    onClick={closeModal}
    className={styles.background}
    variants={variants.background}
    initial="hidden"
    animate="visible"
    exit="exit"
  />
);

const Content = ({ children, childKey, closeModal }: Props) => (
  <AnimatePresence mode='wait'>
    <motion.div
      key={`modal-content-${childKey}`}
      className={styles.content}
      variants={variants.content}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <CloseIcon closeModal={closeModal} />
      {children}
    </motion.div>
  </AnimatePresence>
);

export const CloseIcon = ({ closeModal }: Pick<Props, "closeModal">) => (
  <span className={styles.close} onClick={closeModal}>
    &times;
  </span>
);

const Modal = ({ children, childKey, closeModal }: Props) => (
  <motion.div
    className={styles.modal}
    variants={variants.h}
    initial="hidden"
    animate="visible"
    exit="exit"
  >
    <Background closeModal={closeModal} />
    <Content childKey={childKey} closeModal={closeModal}>
      {children}
    </Content>
  </motion.div>
);

export default Modal;
