import React from "react";

import styles from "./ModalNavigation.module.css";

import constants from "constants/fi.json";

interface Props {
  browse: (direction: 1 | -1) => void;
}

const ModalNavigation = ({ browse }: Props) => {
  return (
    <div className={styles.navi}>
      <div className={styles.prev} onClick={() => browse(-1)}>
        {constants.previous}
      </div>
      <div className={styles.next} onClick={() => browse(1)}>
        {constants.next}
      </div>
    </div>
  );
};

export default ModalNavigation;
