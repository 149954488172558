import React, { useState } from "react";

import getData from "utils/getData";
import { WP_REST_API_Post } from "types/WP";

const TAC_PAGE_ID = 3;

const TermsAndConditions = () => {
  const [page, setPage] = useState<WP_REST_API_Post>();
  if (!page) {
    getData((result: WP_REST_API_Post) => {
      setPage(result);
    }, `pages/${TAC_PAGE_ID}`);
  }

  return (
    <div
      className="content-wrapper"
      style={{ textAlign: "left" }}
      dangerouslySetInnerHTML={{ __html: page?.content.rendered || "" }}
    ></div>
  );
};

export default TermsAndConditions;
