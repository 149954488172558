import { get } from "lodash-es";

import useStore from "utils/useStore";

const useAgency = (paths?: string[]) => {
  const agencyId = useStore((state) => state.agency);
  const agencies = useStore((state) => state.agencies);

  const agency = agencies.find(({ id }) => id === agencyId);

  if (paths) {
    return paths.map((path) => get(agency, path, null));
  }

  return [agency];
};

export default useAgency;
