import React from "react";
import { Link } from "react-router-dom";

import styles from "./Previous.module.css";

import constants from "constants/fi.json";

interface Props {
  to: string | (() => void);
  spanStyles?: string;
}

const Previous = ({ to, spanStyles }: Props) => {
  const spanClasses = ["h4", spanStyles].join(" ");

  if (typeof to === "function") {
    return (
      <div className={styles.wrapper}>
        <span className={spanClasses} onClick={() => to()}>
          {constants.previous}
        </span>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Link to={to}>
        <span className={spanClasses}>{constants.previous}</span>
      </Link>
    </div>
  );
};

export default Previous;
