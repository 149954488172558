import { useData } from "utils/getData";
import useStore from "utils/useStore";

const useMappedGuestFlowerPrices = () => {
  const storedAgency = useStore((state) => state.agency);

  const agency = useData(`agency/${storedAgency}`) as any;
  const flowers = useData("guest-flower/?_embed");

  if (!Array.isArray(flowers) || !agency) {
    return [];
  }

  const agencyGuestFlowers =
    agency && agency.acf ? agency.acf.agency_guest_flowers : null;

  if (!agencyGuestFlowers) {
    return [];
  }

  const mappedGuestFlowers = agencyGuestFlowers
    .map((agencyGuestFlower: any) => {
      const mappedGuestFlower = flowers.find(
        (flower: any) => flower.id === agencyGuestFlower.guest_flowers
      );

      if (!mappedGuestFlower) {
        return null;
      }

      mappedGuestFlower.price = agencyGuestFlower.price;

      return mappedGuestFlower;
    })
    .filter((flowers: any) => !!flowers);

  return Array.isArray(mappedGuestFlowers) ? mappedGuestFlowers : [];
};

export default useMappedGuestFlowerPrices;
