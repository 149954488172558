import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import useStore from "utils/useStore";
import usePhase from "utils/usePhase";
import analytics from "utils/analytics";

import Button from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";
import Modal from "components/Modal";
import CardRow from "components/CardRow";
import Previous from "components/Previous";
import Bottom from "components/Bottom";

import constants from "constants/fi.json";

import { FadeUp } from "animations";

import useCoffins from "./useCoffins";
import useCoffinStore from "./useCoffinStore";

const EMPTY_COFFIN = {
  id: 0,
  title: constants.continue_without_coffin,
  price: "0",
};

const Coffin = () => {
  const t = usePhase("coffin");

  const calculateTotalCost = useStore((state) => state.calculateCost);
  const needsUrnSelection = useStore((state) => state.needsUrnSelection)();

  const setUserSelection = useStore((state) => state.setUserSelection);

  const [coffin, setCoffin, coffinOptions, setCoffinOptions, undo] =
    useCoffinStore();

  const [coffins, setOpenModalIndex, openModalIndex, ModalContent] =
    useCoffins();
  const closeModal = () => setOpenModalIndex(null);

  const setEmptyCoffin = () => {
    setUserSelection("coffin", EMPTY_COFFIN);

    setCoffin(EMPTY_COFFIN);

    analytics.pushSelection({
      selectionTitle: "Coffin",
      selectionValue: EMPTY_COFFIN.title,
    });
  };

  useEffect(() => {
    if (coffin === null) {
      setUserSelection("coffin", null);
    }

    if (coffinOptions === null) {
      setUserSelection("coffinOptions", null);
    }
  }, [coffin, coffinOptions, setUserSelection]);

  return (
    <>
      <div className="App-content">
        {coffins.length > 0 && (
          <Bubble disableAutomaticScroll>
            {t("coffins_what_is_best", "%amount%", String(coffins.length))}
          </Bubble>
        )}

        <AnimatePresence mode='wait'>
          {openModalIndex !== null && (
            <Modal closeModal={closeModal} childKey={`modal-${openModalIndex}`}>
              <ModalContent
                closeModal={closeModal}
                setCoffin={setCoffin}
                setCoffinOptions={setCoffinOptions}
                selected={null}
              />
            </Modal>
          )}
        </AnimatePresence>

        {coffin !== null && (
          <>
            <UserBubble>
              {coffin.title === EMPTY_COFFIN.title
                ? t("user_has_skipped_coffin")
                : t(
                    "user_has_selected_coffin",
                    "%selected_coffin%",
                    coffin.title
                  )}
            </UserBubble>
            <Bubble delay={0.75}>
              {t(
                needsUrnSelection
                  ? "thanks_tell_price_and_next_urn"
                  : "thanks_tell_price_and_next_preparation",
                "%price%",
                String(calculateTotalCost())
              )}
            </Bubble>
          </>
        )}
      </div>
      <Bottom>
        {coffin === null && (
          <>
            <CardRow posts={coffins} select={setOpenModalIndex} />

            <Button secondary topGap onClick={setEmptyCoffin}>
              {EMPTY_COFFIN.title}
            </Button>
          </>
        )}

        {coffin !== null && (
          <FadeUp delay={1.5}>
            <Link
              to={needsUrnSelection ? "/uurnan-valinta" : "/arkkuun-valmistelu"}
            >
              <Button>{constants.next}</Button>
            </Link>
          </FadeUp>
        )}

        <Previous to={undo("/arkun-valinta")} />
      </Bottom>
    </>
  );
};

export default Coffin;
