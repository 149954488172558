import React from "react";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import useStore from "utils/useStore";
import usePhase from "utils/usePhase";
import analytics from "utils/analytics";

import Button from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";
import Modal from "components/Modal";
import Previous from "components/Previous";
import CardRow from "components/CardRow";
import Bottom from "components/Bottom";

import constants from "constants/fi.json";

import { FadeUp } from "animations";

import useUrns from "./useUrns";
import useUrnStore from "./useUrnStore";
import useRecommendedString from "./useRecommendedString";

const EMPTY_URN = {
  id: 0,
  title: constants.continue_without_urn,
  price: "0",
};

const Urn = () => {
  const t = usePhase("urn");

  const [urn, setUrn, undo] = useUrnStore();

  const [urns, setOpenModalIndex, openModalIndex, ModalContent] = useUrns();
  const closeModal = () => setOpenModalIndex(null);

  const calculateTotalCost = useStore((state) => state.calculateCost);
  const userCoffin = useStore((state) => state.coffin);

  const recommendedString = useRecommendedString(urns);

  const setEmptyUrn = () => {
    setUrn(EMPTY_URN);

    analytics.pushSelection({
      selectionTitle: "Urn",
      selectionValue: EMPTY_URN.title,
    });
  };

  return (
    <>
      <div className="App-content">
        {urns.length > 0 && userCoffin && (
          <Bubble>
            {t(
              "user_has_selected_coffin",
              "%coffin%",
              String(userCoffin.title)
            )}{" "}
            {recommendedString &&
              t(
                "which_urn_with_recommended",
                "%recommended%",
                recommendedString
              )}
            {!recommendedString && t("which_urn")}
          </Bubble>
        )}

        <AnimatePresence mode='wait'>
          {openModalIndex !== null && (
            <Modal closeModal={closeModal} childKey={`modal-${openModalIndex}`}>
              <ModalContent closeModal={closeModal} setUrn={setUrn} />
            </Modal>
          )}
        </AnimatePresence>

        {urn && (
          <>
            <UserBubble>
              {urn.title === EMPTY_URN.title
                ? t("user_has_skipped_urn")
                : t("user_has_selected_urn", "%selected_urn%", urn.title)}
            </UserBubble>
            <Bubble delay={0.75}>
              {t(
                "thanks_tell_price_and_next",
                "%price%",
                String(calculateTotalCost())
              )}
            </Bubble>
          </>
        )}
      </div>
      <Bottom>
        {!urn && (
          <>
            <CardRow posts={urns} select={setOpenModalIndex} />

            <Button secondary topGap onClick={setEmptyUrn}>
              {EMPTY_URN.title}
            </Button>
          </>
        )}
        {urn && (
          <FadeUp delay={1.5}>
            <Link to="/arkkuun-valmistelu">
              <Button>{constants.next}</Button>
            </Link>
          </FadeUp>
        )}

        <Previous to={undo("/uurnan-valinta")} />
      </Bottom>
    </>
  );
};

export default Urn;
