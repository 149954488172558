import React from "react";

import styles from "./CheckboxList.module.css";
import Checkbox from "./Checkbox";
import { SelectableItem, UserSelection } from "utils/useStore";

interface Props {
  title?: string;
  noOptionTitle?: string;
  selected: UserSelection;
  options: SelectableItem[];
  radio?: boolean;
  select: (selected: UserSelection) => void;
}

const RadioList = ({
  title,
  noOptionTitle,
  selected,
  select,
  options,
}: Props) => {
  if (!options || options.length < 1) {
    return null;
  }

  const isFilled = (item: SelectableItem) => {
    if (!selected) {
      return false;
    }

    return selected.id === item.id;
  };

  return (
    <div>
      {title && <div className={["h4", styles.title].join(" ")}>{title}</div>}
      <div>
        {noOptionTitle && (
          <Checkbox
            key={"nosize"}
            filled={selected === null}
            onClick={() => select(null)}
            radio={true}
          >
            {noOptionTitle}
          </Checkbox>
        )}
        {options.map((item) => (
          <Checkbox
            key={item.id}
            filled={isFilled(item)}
            onClick={() => select(item)}
            radio={true}
          >
            {item.title}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default RadioList;
