import useStore from "utils/useStore";

const useRecommendedString = (urns: any) => {
  const userRecommendedUrns = useStore((state) => state.recommendedUrns);

  if (!userRecommendedUrns || !urns) {
    return false;
  }

  const recommendedTitles = userRecommendedUrns
    .map((recommendedUrnId) => {
      const recommendedUrn =
        urns?.find((urn: any) => urn.id === recommendedUrnId) || null;

      if (!recommendedUrn) {
        return null;
      }

      return recommendedUrn.title.rendered;
    })
    .filter((urn) => !!urn);

  if (recommendedTitles.length < 1) {
    return false;
  }

  return recommendedTitles.join(", ");
};

export default useRecommendedString;
