import React from "react";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useEditing from "./useEditing";
import useStore from "utils/useStore";

import Modal from "components/Modal";
import Button, { Buttons } from "components/Button";
import { Link } from "react-router-dom";

const Burial = () => {
  const t = usePhase("summary");
  const burial = useStore((state) => state.burial);

  const [isEditing, Edit, ConfirmCancel, modalOpen, UnEditable, closeModal] =
    useEditing();

  const confirm = () => {
    return;
  };

  return (
    <div className={styles.block}>
      <div className={styles.subtitle}>
        <h3 className="h3">{t("burial_method")}</h3>
        <Edit />
      </div>
      {!isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("burial_method")}</div>
            <div className={styles.value}>{burial?.title}</div>
          </div>
        </div>
      )}
      {isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("burial_method")}</div>
            <UnEditable>{burial?.title}</UnEditable>
          </div>
        </div>
      )}
      <ConfirmCancel confirm={confirm} />
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <span className="h2">Huom!</span>
          <p className="paragraph">
            Nämä valinnat vaikuttavat kaikkeen. Jos haluat muokata, joudut
            palaamaan alkuun.
          </p>
          <Buttons insideModal>
            <Link to="/hautaustapa/">
              <Button tiny secondary>
                Aloita alusta
              </Button>
            </Link>
            <Button tiny onClick={closeModal}>
              Peruuta
            </Button>
          </Buttons>
        </Modal>
      )}
    </div>
  );
};

export default Burial;
