import React from "react";

import EmbeddedImage from "./EmbeddedImage";

import styles from "./Card.module.css";
import { WP_REST_API_Post } from "types/WP";

interface Post extends WP_REST_API_Post {
  price?: string;
}

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  post: Post;
}

const Card = ({ onClick, post }: Props) => {
  const title = post.title.rendered;
  const price = post.price;

  return (
    <div className={styles.card} onClick={onClick ? onClick : undefined}>
      {title && <div className={["h4", styles.title].join(" ")}>{title}</div>}
      {price && <div className={styles.price}>{price} €</div>}
      <EmbeddedImage post={post} tiny />
      <div className={styles.showmore}>Näytä tiedot</div>
    </div>
  );
};

export default Card;
