import { useNavigate } from "react-router-dom";
import useStore, { UserSelection } from "utils/useStore";

const useFlowersStore = () => {
  const setUserSelection = useStore((state) => state.setUserSelection);
  const navigate = useNavigate();

  const coffinFlower = useStore((state) => state.coffinFlower);
  const setCoffinFlower = (coffinFlower: UserSelection) =>
    setUserSelection("coffinFlower", coffinFlower);

  const coffinFlowerSizes = useStore((state) => state.coffinFlowerSizes);
  const setCoffinFlowerSizes = (coffinFlowerSizes: UserSelection) =>
    setUserSelection("coffinFlowerSizes", coffinFlowerSizes);

  const guestFlower = useStore((state) => state.guestFlower);
  const setGuestFlower = (guestFlower: UserSelection) =>
    setUserSelection("guestFlower", guestFlower);

  const undo = (previousPath: string) => () => {
    if (guestFlower) {
      setGuestFlower(null);
    } else if (coffinFlower) {
      setCoffinFlowerSizes(null);
      setCoffinFlower(null);
    } else if (previousPath) {
      navigate(previousPath);
    }
  };

  return [
    coffinFlower,
    setCoffinFlower,
    coffinFlowerSizes,
    setCoffinFlowerSizes,
    guestFlower,
    setGuestFlower,
    undo,
  ] as const;
};

export default useFlowersStore;
