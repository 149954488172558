import { get } from "lodash-es";

const useMunicipalityLanguageCase = (municipalities: any) => {
  return (id: number, key: "in" | "from" | "to") => {
    if (!municipalities) {
      return "";
    }

    const municipality = municipalities.find((item: any) => item.id === id);

    return String(
      get(municipality, `meta.municipality-language-case-${key}`, "")
    );
  };
};

export default useMunicipalityLanguageCase;
