import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./Checkbox.module.css";

interface Props {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  filled: boolean;
  radio?: boolean;
}

const Checkbox = ({ children, onClick, filled, radio }: Props) => {
  const classes = [styles.checkbox];

  if (filled) {
    classes.push(styles.filled);
  }

  const iconStyle = radio ? styles.radio : styles.checkmark;

  return (
    <AnimatePresence mode='wait'>
      <div onClick={onClick} className={classes.join(" ")}>
        <div className={iconStyle} />
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          {children}
        </motion.span>
      </div>
    </AnimatePresence>
  );
};

export default Checkbox;
