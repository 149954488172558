import { useNavigate } from "react-router-dom";
import useStore, { UserSelection } from "utils/useStore";

const useUrnStore = () => {
  const urn = useStore((state) => state.urn);
  const setUserSelection = useStore((state) => state.setUserSelection);
  const navigate = useNavigate();

  const setUrn = (urn: UserSelection) =>
    setUserSelection("urn", urn);

  const undo = (previousPath: string) => () => {
    if (urn) {
      setUserSelection("urn", null);
    } else if (previousPath) {
      navigate(previousPath);
    }
  };

  return [urn, setUrn, undo] as const;
};

export default useUrnStore;
