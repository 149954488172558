import { useNavigate } from "react-router-dom";
import useStore, { UserSelection } from "utils/useStore";

const useBurialStore = () => {
  const burial = useStore((state) => state.burial);
  const setUserSelection = useStore((state) => state.setUserSelection);
  const navigate = useNavigate();

  const setBurial = (burial: UserSelection) =>
    setUserSelection("burial", burial);

  const undo = (previousPath: string) => () => {
    if (burial) {
      setUserSelection("burial", null);
    } else if (previousPath) {
      navigate(previousPath);
    }
  };

  return [burial, setBurial, undo] as const;
};

export default useBurialStore;
