import React, { useState } from "react";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useEditing from "./useEditing";
import useStore, { UserSelection } from "utils/useStore";
import { AnimatePresence } from "framer-motion";
import useUrns from "views/urn/useUrns";
import Modal from "components/Modal";

const Urn = () => {
  const t = usePhase("summary");
  const storedUrn = useStore((state) => state.urn);
  const needsUrnSelection = useStore((state) => state.needsUrnSelection)();
  const setUserSelection = useStore((state) => state.setUserSelection);

  const [urn, setUrn] = useState<UserSelection>(null);

  const [urns, setOpenModalIndex, openModalIndex, ModalContent] = useUrns();
  const closeModal = () => setOpenModalIndex(null);

  const storedUrnIndex =
    storedUrn?.id && urns ? urns.findIndex(({ id }) => storedUrn.id === id) : 0;

  const [isEditing, Edit, ConfirmCancel] = useEditing();

  const confirm = () => {
    if (urn) {
      setUserSelection("urn", urn);
    }
  };

  if (!needsUrnSelection) {
    return null;
  }

  return (
    <div className={styles.block}>
      <div className={styles.subtitle}>
        <h3 className="h3">{t("urn")}</h3>
        <span className={styles.price}>{storedUrn?.price} €</span>
        <Edit />
      </div>
      {!isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("urn")}</div>
            <div className={styles.value}>
              {storedUrn?.title}
              <div className={styles.partprice}>{storedUrn?.price} €</div>
            </div>
          </div>
        </div>
      )}
      {isEditing && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.key}>{t("urn")}</div>
            <div
              onClick={() => setOpenModalIndex(storedUrnIndex)}
              className={styles.editingValue}
            >
              {urn ? urn.title : storedUrn?.title}
              <div className={styles.partprice}>
                {urn ? urn.price : storedUrn?.price} €
              </div>
            </div>
          </div>
        </div>
      )}
      <AnimatePresence mode='wait'>
        {openModalIndex !== null && (
          <Modal closeModal={closeModal} childKey={`modal-${openModalIndex}`}>
            <ModalContent
              closeModal={closeModal}
              setUrn={setUrn}
              dontChangeState
            />
          </Modal>
        )}
      </AnimatePresence>
      <ConfirmCancel confirm={confirm} />
    </div>
  );
};

export default Urn;
