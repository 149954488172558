import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useEditing from "./useEditing";
import useStore, { UserSelection } from "utils/useStore";

import Modal from "components/Modal";
import useClothing from "views/preparation/useClothing";

const Preparation = () => {
  const t = usePhase("summary");
  const clothing = useStore((state) => state.clothing);
  const [isEditing, Edit, ConfirmCancel] = useEditing();
  const setUserSelection = useStore((state) => state.setUserSelection);

  const [clothings, setOpenModalIndex, openModalIndex, ModalContent] =
    useClothing();

  const [userClothing, setUserClothing] = useState<UserSelection>(null);

  const closeModal = () => setOpenModalIndex(null);

  const clothingIndex =
    clothing && clothings
      ? clothings.findIndex(({ id }) => clothing.id === id)
      : 0;

  const confirm = () => {
    if (userClothing) {
      setUserSelection("clothing", userClothing);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      setUserClothing(null);
    }
  }, [isEditing]);

  return (
    <>
      <AnimatePresence mode='wait'>
        {openModalIndex !== null && (
          <Modal closeModal={closeModal} childKey={`modal-${openModalIndex}`}>
            <ModalContent
              setClothing={setUserClothing}
              closeModal={closeModal}
              dontChangeState
            />
          </Modal>
        )}
      </AnimatePresence>
      <div className={styles.block}>
        <div className={styles.subtitle}>
          <h3 className="h3">{t("coffin_preparation")}</h3>
          <span className={styles.price}>
            {clothing ? clothing.price : 0} €
          </span>
          <Edit />
        </div>
        {!isEditing && (
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.key}>{t("clothing")}</div>
              <div className={styles.value}>
                {clothing?.title}
                <div className={styles.partprice}>{clothing?.price} €</div>
              </div>
            </div>
          </div>
        )}
        {isEditing && (
          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.key}>{t("clothing")}</div>
              <div
                onClick={() => setOpenModalIndex(clothingIndex)}
                className={styles.editingValue}
              >
                {userClothing ? userClothing.title : clothing?.title}
                <div className={styles.partprice}>
                  {userClothing ? userClothing.price : clothing?.price} €
                </div>
              </div>
            </div>
          </div>
        )}
        <ConfirmCancel confirm={confirm} />
      </div>
    </>
  );
};

export default Preparation;
