import React from "react";
import { Link, useParams } from "react-router-dom";

import { WP_REST_API_Post } from "types/WP";

import parseEmbeddedImage from "utils/parseEmbeddedImage";
import useStore from "utils/useStore";
import analytics from "utils/analytics";

import Button from "components/Button";

import constants from "constants/fi.json";

import styles from "./agency.module.css";

interface Props {
  agency: WP_REST_API_Post;
}

const AgencyHeader = ({ agency }: Props) => {
  const image = parseEmbeddedImage(agency, "full");

  if (!image) {
    return null;
  }

  return (
    <div className={styles.header}>
      <img src={image.src} alt={image.alt} />
    </div>
  );
};

const AgencyContent = ({ agency }: Props) => {
  const setAgency = useStore((state) => state.setAgency);
  const setAgencyIsForced = useStore((state) => state.setAgencyIsForced);

  return (
    <div className={styles.contentWrapper}>
      {agency.content.rendered && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: agency.content.rendered }}
        />
      )}
      <Link to="/">
        <Button
          uppercase
          onClick={() => {
            setAgency(agency.id);
            setAgencyIsForced(true);

            analytics.pushSelection({
              selectionTitle: "Set agency",
              selectionValue: String(agency.title.rendered),
            });
          }}
        >
          {constants.start_process}
        </Button>
      </Link>
    </div>
  );
};

const Agency = () => {
  const agencies = useStore((state) => state.agencies);
  let { agencySlug } = useParams();

  if (!agencySlug || !agencies) {
    return null;
  }

  const agency = agencies.find(({ slug }) => slug === agencySlug);

  if (!agency) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <AgencyHeader agency={agency} />
      <AgencyContent agency={agency} />
    </div>
  );
};

export default Agency;
