import React, { useEffect } from "react";
import { motion } from "framer-motion";
import useScrollToTop from "utils/useScrollToTop";

interface Props {
  children: React.ReactNode;
  className?: string | undefined;
  delay?: number | undefined;
  layoutId?: string | undefined;
  scrollToTop?: boolean;
}

export const FadeUp = ({
  children,
  className,
  delay = 0.25,
  scrollToTop,
}: Props) => {
  const [executeScroll] = useScrollToTop();

  useEffect(() => {
    if (scrollToTop) {
      executeScroll();
    }
  });

  if (scrollToTop) {
    delay = delay + 0.1;
  }

  return (
    <motion.div
      className={className}
      initial={{ opacity: 0, y: "3rem" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.75, delay }}
    >
      {children}
    </motion.div>
  );
};

export const FadeIn = ({ children, className, delay, layoutId }: Props) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25, delay }}
      layoutId={layoutId}
    >
      {children}
    </motion.div>
  );
};

export const FadeUpToRight = ({ children, className, delay = 0.25 }: Props) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0, y: "3rem" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: "3rem" }}
      transition={{ ease: "easeOut", duration: 0.75, delay }}
    >
      {children}
    </motion.div>
  );
};

const animations = {
  FadeUp: FadeUp,
  FadeIn: FadeIn,
  FadeUpToRight: FadeUpToRight,
};

export default animations;
