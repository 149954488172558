import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Button, { Buttons } from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import Previous from "components/Previous";

import { FadeUp } from "animations";

import validate from "utils/validate";
import { useData } from "utils/getData";
import usePhase from "utils/usePhase";
import analytics from "utils/analytics";

import constants from "constants/fi.json";
import Bottom from "components/Bottom";
import useContactStore from "./useContactStore";

const EMPTY_RELIGION = {
  id: 0,
  title: constants.skip,
};

export const useReligions = () => {
  const religions = useData("religious-denomination");

  return Array.isArray(religions)
    ? religions
        .map(({ id, title }) => {
          return { id: Number(id), title: title.rendered };
        })
        .sort((a, b) => a.title.localeCompare(b.title, "fi"))
    : [];
};

const Contact = () => {
  const religions = useReligions();
  const t = usePhase("contact");
  const [
    clientName,
    setClientName,
    clientPhone,
    setClientPhone,
    clientEmail,
    setClientEmail,
    skipDeceasedInfo,
    setSkipDeceasedInfo,
    deceasedName,
    setDeceasedName,
    deceasedId,
    setDeceasedId,
    deceasedInChurch,
    setDeceasedInChurch,
    religion,
    setReligion,
    wishes,
    setWishes,
    relationship,
    setRelationship,
    undo,
  ] = useContactStore();

  const isChurchSelected =
    (deceasedInChurch === "yes" && religion !== null) ||
    deceasedInChurch === "no" ||
    deceasedInChurch === "unknown";

  const setInChurch = (value: "yes" | "no" | "unknown" | null) => {
    setDeceasedInChurch(value);

    if (value === "no") {
      setReligion({
        id: 0,
        title: t("deceased_not_in_church"),
      });
    }

    if (value === "unknown") {
      setReligion({
        id: 0,
        title: constants.skip_question,
      });
    }
  };

  useEffect(() => {
    if (deceasedName !== null) {
      analytics.pushSelection({
        selectionTitle:
          deceasedName === constants.skip_question
            ? "User skipped name of deceased"
            : "User gave name of deceased",
      });
    }
  }, [deceasedName]);

  useEffect(() => {
    if (deceasedId !== null) {
      analytics.pushSelection({
        selectionTitle:
          deceasedId === constants.skip_question
            ? "User skipped social id of deceased"
            : "User gave social id of deceased",
      });
    }
  }, [deceasedId]);

  useEffect(() => {
    if (deceasedInChurch !== null) {
      analytics.pushSelection({
        selectionTitle: "User gave church of deceased",
      });
    }
  }, [deceasedInChurch]);

  useEffect(() => {
    if (religion !== null) {
      analytics.pushSelection({
        selectionTitle:
          religion.title === constants.skip_question
            ? "User skipped religion of deceased"
            : "User gave religion of deceased",
      });
    }
  }, [religion]);

  useEffect(() => {
    if (wishes !== null) {
      analytics.pushSelection({
        selectionTitle:
          wishes === constants.skip_question
            ? "User skipped wishes of deceased"
            : "User gave wishes of deceased",
      });
    }
  }, [wishes]);

  useEffect(() => {
    if (clientName !== null) {
      analytics.pushSelection({
        selectionTitle: "User gave their own name",
      });
    }
  }, [clientName]);

  useEffect(() => {
    if (clientPhone !== null) {
      analytics.pushSelection({
        selectionTitle:
          clientPhone === constants.skip_question
            ? "User skipped their own phone"
            : "User gave their own phone",
      });
    }
  }, [clientPhone]);

  useEffect(() => {
    if (clientEmail !== null) {
      analytics.pushSelection({
        selectionTitle: "User gave their own email",
      });
    }
  }, [clientEmail]);

  useEffect(() => {
    if (relationship !== null) {
      analytics.pushSelection({
        selectionTitle: "User gave relationship between them and deceased",
      });
    }
  }, [relationship]);

  useEffect(() => {
    if (skipDeceasedInfo === "yes") {
      analytics.pushSelection({
        selectionTitle: "User skipped Deceased info",
      });
    }
  }, [skipDeceasedInfo]);

  const clientHasGivenTheirInfo =
    clientName !== null && clientEmail !== null && clientPhone !== null;

  return (
    <>
      <div className="App-content">
        <Bubble disableAutomaticScroll>{t("go_through_contact_info")}</Bubble>
        <Bubble delay={0.75}>{t("tell_your_contact_info")}</Bubble>

        {clientHasGivenTheirInfo && (
          <>
            <UserBubble>
              {[clientName, clientPhone, clientEmail]
                .filter((value) => value && value !== constants.skip_question) // In case phone is empty
                .join(", ")}
            </UserBubble>
            <Bubble delay={0.75}>{t("thanks_want_to_go_to_summary")}</Bubble>
          </>
        )}

        {skipDeceasedInfo === "no" && (
          <>
            <UserBubble>{t("continue_on_service")}</UserBubble>

            <Bubble delay={0.75} disableAutomaticScroll>
              {t("tell_deceased_name")}
            </Bubble>

            {deceasedName !== null && deceasedId !== null && (
              <>
                <UserBubble>
                  {/* both deceasedId & deceasedName skipped  */}
                  {deceasedId === constants.skip_question &&
                    deceasedName === constants.skip_question && (
                      <>{t("both_id_name_skipped")}</>
                    )}

                  {/* deceasedName given, deceasedId skipped  */}
                  {deceasedId === constants.skip_question &&
                    deceasedName !== constants.skip_question && (
                      <>{t("name_given_id_skipped", "%name%", deceasedName)}</>
                    )}

                  {/* deceasedId given, deceasedName skipped  */}
                  {deceasedName === constants.skip_question &&
                    deceasedId !== constants.skip_question && (
                      <>{t("id_given_name_skipped", "%id%", deceasedId)}</>
                    )}

                  {/* both deceasedId & deceasedName given  */}
                  {deceasedName !== constants.skip_question &&
                    deceasedId !== constants.skip_question && (
                      <>
                        {t("deceased_name_is", "%name%", deceasedName)}{" "}
                        {t("social_id", "%id%", deceasedId)}
                      </>
                    )}
                </UserBubble>
                <Bubble delay={0.75}>{t("is_deceased_in_church")}</Bubble>
              </>
            )}
            {deceasedInChurch === "yes" && (
              <>
                <UserBubble>{t("deceased_in_church")}</UserBubble>
                <Bubble delay={0.75}>{t("what_church")}</Bubble>

                {religion !== null && (
                  <UserBubble>
                    {religion.id !== EMPTY_RELIGION.id
                      ? t("deceased_belongs_in", "%church%", religion.title)
                      : EMPTY_RELIGION.title}
                  </UserBubble>
                )}
              </>
            )}
            {deceasedInChurch === "no" && (
              <UserBubble>{t("deceased_not_in_church")}</UserBubble>
            )}
            {deceasedInChurch === "unknown" && (
              <UserBubble>{constants.skip_question}</UserBubble>
            )}
            {isChurchSelected && (
              <Bubble delay={0.75}>
                {t("thanks_did_deceased_have_wishes")}
              </Bubble>
            )}

            {wishes !== null && (
              <>
                <UserBubble>{wishes}</UserBubble>
                <Bubble delay={0.75}>{t("thanks_now_relationship")}</Bubble>
              </>
            )}
          </>
        )}
        {skipDeceasedInfo !== "yes" && relationship !== null && (
          <>
            <UserBubble>{relationship}</UserBubble>
            <Bubble delay={1}>{t("thanks_next_summary")}</Bubble>
          </>
        )}
        {skipDeceasedInfo === "yes" && (
          <>
            <UserBubble> {t("thanks_continue_on_phone")}</UserBubble>
            <Bubble delay={1}>{t("thanks_next_summary")}</Bubble>
          </>
        )}
      </div>
      <Bottom>
        {clientName === null && (
          <FadeUp delay={1.5}>
            <TextInput
              setValue={setClientName}
              placeholder={t("write_orderer_name")}
            />
          </FadeUp>
        )}

        {clientName !== null && clientPhone === null && (
          <FadeUp delay={0.5}>
            <TextInput
              setValue={setClientPhone}
              placeholder={t("write_orderer_phone")}
            />
            <Button
              secondary
              onClick={() => setClientPhone(constants.skip_question)}
            >
              {constants.skip_question}
            </Button>
          </FadeUp>
        )}

        {clientPhone !== null && clientEmail === null && (
          <FadeUp delay={0.5}>
            <TextInput
              setValue={setClientEmail}
              placeholder={t("write_orderer_email")}
              validate={validate.email}
            />
          </FadeUp>
        )}

        {skipDeceasedInfo === "no" && deceasedName === null && (
          <FadeUp delay={1.5}>
            <TextInput
              setValue={setDeceasedName}
              placeholder={t("write_deceased_name")}
            />
            <Button
              secondary
              onClick={() => setDeceasedName(constants.skip_question)}
            >
              {constants.skip_question}
            </Button>
          </FadeUp>
        )}
        {deceasedName !== null && deceasedId === null && (
          <FadeUp delay={0.5}>
            <TextInput
              setValue={setDeceasedId}
              placeholder={t("write_deceased_id")}
            />
            <Button
              secondary
              onClick={() => setDeceasedId(constants.skip_question)}
            >
              {constants.skip_question}
            </Button>
          </FadeUp>
        )}
        {deceasedId !== null && deceasedInChurch === null && (
          <FadeUp delay={1.5}>
            <Button onClick={() => setInChurch("yes")}>{constants.yes}</Button>
            <Button onClick={() => setInChurch("no")}>{constants.no}</Button>
            <Button secondary onClick={() => setInChurch("unknown")}>
              {constants.skip_question}
            </Button>
          </FadeUp>
        )}

        {deceasedInChurch === "yes" && religion === null && (
          <FadeUp delay={1.5}>
            <Buttons stack>
              <Dropdown
                options={religions}
                onChange={(selected) => {
                  setReligion(selected);
                }}
                placeholder={t("select_religion")}
              />
              <Button secondary onClick={() => setReligion(EMPTY_RELIGION)}>
                {constants.skip_question}
              </Button>
            </Buttons>
          </FadeUp>
        )}

        {isChurchSelected && wishes === null && (
          <FadeUp delay={1.5}>
            <TextInput
              setValue={setWishes}
              placeholder={t("write_deceased_wishes")}
            />
            <Button
              secondary
              onClick={() => setWishes(constants.skip_question)}
            >
              {constants.skip_question}
            </Button>
          </FadeUp>
        )}

        {wishes !== null && (
          <>
            {clientName === null && (
              <FadeUp delay={2.25}>
                <TextInput
                  setValue={setClientName}
                  placeholder={t("write_orderer_name")}
                />
              </FadeUp>
            )}

            {clientName !== null && clientPhone === null && (
              <FadeUp delay={0.5}>
                <TextInput
                  setValue={setClientPhone}
                  placeholder={t("write_orderer_phone")}
                />
                <Button
                  secondary
                  onClick={() => setClientPhone(constants.skip_question)}
                >
                  {constants.skip_question}
                </Button>
              </FadeUp>
            )}

            {clientPhone !== null && clientEmail === null && (
              <FadeUp delay={0.5}>
                <TextInput
                  setValue={setClientEmail}
                  placeholder={t("write_orderer_email")}
                  validate={validate.email}
                />
              </FadeUp>
            )}
          </>
        )}
        {skipDeceasedInfo === null &&
          clientName !== null &&
          clientEmail !== null &&
          clientPhone !== null && (
            <FadeUp delay={1.5}>
              <Button onClick={() => setSkipDeceasedInfo("yes")} secondary>
                {t("thanks_continue_on_phone")}
              </Button>
              <Button onClick={() => setSkipDeceasedInfo("no")}>
                {t("continue_on_service")}
              </Button>
            </FadeUp>
          )}

        {skipDeceasedInfo === "no" &&
          relationship === null &&
          wishes !== null && (
            <FadeUp delay={1.5}>
              <TextInput
                setValue={setRelationship}
                placeholder={t("write_relationship")}
              />
            </FadeUp>
          )}

        {(relationship !== null || skipDeceasedInfo === "yes") && (
          <FadeUp delay={1.5}>
            <Link to="/yhteenveto">
              <Button>{constants.next}</Button>
            </Link>
          </FadeUp>
        )}

        <Previous to={undo("/yhteystiedot")} />
      </Bottom>
    </>
  );
};

export default Contact;
