import { useRef } from "react";

const useScrollToElement = () => {
  const elRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    if (elRef.current) {
      elRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return [executeScroll, elRef] as const;
};

export default useScrollToElement;
