import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import { Rest } from "types/rest";

import getMessagesAndIcons from "utils/getMessagesAndIcons";
import { useData } from "utils/getData";
import useStore from "utils/useStore";
import analytics from "utils/analytics";

import Agency from "./agency";
import Intro from "./intro";
import Transport from "./transport";
import Burial from "./burial";
import Coffin from "./coffin";
import Urn from "./urn";
import Preparation from "./preparation";
import Flowers from "./flowers";
import Summary from "./summary";
import Thanks from "./thanks";
import Loading from "./loading";
import TermsAndConditions from "./tac";
import Header from "components/Header";
import Cover from "components/Cover";

import { FadeUp } from "animations";
import BackgroundImage from "components/BackgroundImage";

import styles from "./views.module.css";

type RouteProps = {
  path: string;
  element: JSX.Element;
  cover?: boolean;
};

export const routes = [
  {
    path: "/",
    element: <Intro />,
  },
  {
    path: "/kuljetukset",
    element: <Cover name="transport" />,
    cover: true,
  },
  {
    path: "/kuljetukset/vaihe-2",
    element: <Transport />,
  },
  {
    path: "/hautaustapa",
    element: <Cover name="burial" />,
    cover: true,
  },
  {
    path: "/hautaustapa/vaihe-2",
    element: <Burial />,
  },
  {
    path: "/arkun-valinta",
    element: <Cover name="coffin" />,
    cover: true,
  },
  {
    path: "/arkun-valinta/vaihe-2",
    element: <Coffin />,
  },
  {
    path: "/uurnan-valinta",
    element: <Cover name="urn" />,
    cover: true,
  },
  {
    path: "/uurnan-valinta/vaihe-2",
    element: <Urn />,
  },
  {
    path: "/arkkuun-valmistelu",
    element: <Cover name="preparation" />,
    cover: true,
  },
  {
    path: "/arkkuun-valmistelu/vaihe-2",
    element: <Preparation />,
  },
  {
    path: "/kukat",
    element: <Cover name="flowers" />,
    cover: true,
  },
  {
    path: "/kukat/vaihe-2",
    element: <Flowers />,
  },
  {
    path: "/yhteenveto",
    element: <Cover name="summary" />,
    cover: true,
  },
  {
    path: "/yhteenveto/vaihe-2",
    element: <Summary />,
  },
  {
    path: "/yhteenveto/:hash",
    element: <Summary />,
  },
  {
    path: "/kiitos",
    element: <Thanks />,
  },
];

const getProgress = (pathname: string) => {
  const index = 1 + routes.findIndex(({ path }) => path === pathname);

  return Math.round((index / routes.length) * 100);
};

const isCoverStyle = (pathname: string) => {
  const routeItem = routes.find(({ path }) => path === pathname);

  return routeItem?.cover === true;
};

const Views = () => {
  const location = useLocation();
  const setPhases = useStore((state) => state.setPhases);
  const setAgenices = useStore((state) => state.setAgenices);
  const setIconsToStore = useStore((state) => state.setIcons);

  const agencies = useData("agency/?_embed&per_page=100");

  const [messages, setMessages] = useState<Rest.Messages>();
  const [icons, setIcons] = useState<(string | null)[]>();

  useEffect(() => getMessagesAndIcons(setMessages, setIcons), []);
  useEffect(() => {
    analytics.pushVirtualPageview({
      siteSection: location.pathname,
    });
  }, [location.pathname]);

  if (messages && icons && agencies) {
    setPhases(messages);
    setIconsToStore(icons);
    setAgenices(agencies);
  } else {
    return <Loading />;
  }

  const progress = getProgress(location.pathname);
  const cover = isCoverStyle(location.pathname);

  const backgroundColor = cover ? "var(--dark-purple)" : "var(--light-beige)";

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        className={styles.app}
        animate={{ backgroundColor }}
        transition={{ delay: 1 }}
      >
        <Header cover={cover} progress={progress} />

        <BackgroundImage cover={cover} />

        <AnimatePresence mode='wait'>
          <FadeUp scrollToTop key={location.pathname} delay={0}>
            <div className="content-wrapper">
              <Routes location={location} key={location.pathname}>
                <Route path="/toimisto/:agencySlug" element={<Agency />} />
                <Route
                  path="/tietosuojaseloste"
                  element={<TermsAndConditions />}
                />
                {routes.map((props: RouteProps) => (
                  <Route {...props} key={props.path} />
                ))}
              </Routes>
            </div>
          </FadeUp>
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};

export default Views;
