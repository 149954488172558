import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import useStore from "utils/useStore";

import Button, { Buttons } from "./Button";
import TagManager from "react-gtm-module";

import { FadeIn } from "animations";

import constants from "constants/fi.json";

import styles from "./CookieBanner.module.css";

const CookieBanner = () => {
  const enableCookies = useStore((state) => state.enableCookies);
  const disableCookies = useStore((state) => state.disableCookies);
  const cookiesReacted = useStore((state) => state.cookiesReacted);
  const acceptCookies = useStore((state) => state.acceptCookies);

  useEffect(() => {
    if (acceptCookies) {
      TagManager.initialize({ gtmId: "GTM-MS8TL67" });
    }
  }, [acceptCookies]);

  return (
    <AnimatePresence mode='wait'>
      {!cookiesReacted && (
        <FadeIn>
          <div className={styles.banner}>
            <div className={styles.container}>
              <Buttons>
                <Button tiny onClick={() => enableCookies()}>
                  {constants.cookies_accept}
                </Button>
                <Button tiny secondary onClick={() => disableCookies()}>
                  {constants.cookies_decline}
                </Button>
              </Buttons>
            </div>
          </div>
        </FadeIn>
      )}
    </AnimatePresence>
  );
};

export default CookieBanner;
