import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import parseEmbeddedImage from "utils/parseEmbeddedImage";

import styles from "./EmbeddedImage.module.css";
import { FadeIn } from "animations";
import { CloseIcon } from "./Modal";

type post = any;
type size = "medium" | "thumbnail" | "full" | "large";

interface Props {
  post: post;
  size?: size;
  tiny?: boolean;
  topGap?: boolean;
  zoomable?: boolean;
}

interface ZoomableProps {
  post: post;
  className: string;
  originalImage: {
    src: string;
    alt: string;
  };
}

const ZoomableImage = ({ post, className, originalImage }: ZoomableProps) => {
  const [zoomOn, setZoomOn] = useState<Boolean>(false);
  const openZoom = () => setZoomOn(true);
  const closeZoom = () => setZoomOn(false);

  const image = parseEmbeddedImage(post, "full");

  if (!image) {
    return null;
  }

  return (
    <div className={styles.zoomableWrapper}>
      <img
        onClick={() => openZoom()}
        className={className}
        src={originalImage.src}
        alt={originalImage.alt}
      />
      <div className={styles.zoomIcon} onClick={() => openZoom()} />
      <div
        style={{
          zIndex: 2,
        }}
      >
        <AnimatePresence>
          {zoomOn && (
            <FadeIn>
              <div
                onClick={() => closeZoom()}
                className={styles.zoomableModalWrapper}
              >
                <div className={styles.zoomableModalContent}>
                  <CloseIcon closeModal={() => closeZoom()} />
                  <img src={image.src} alt={image.alt} />
                </div>
              </div>
            </FadeIn>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const EmbeddedImage = ({ post, size, tiny, topGap, zoomable }: Props) => {
  const image = parseEmbeddedImage(post, size);

  if (!image) {
    return <div className={styles.placeholder} />;
  }

  let src = image.src;

  const imageClasses = [styles.image];

  if (size === "full") {
    imageClasses.push(styles.full);
  }

  if (size === "medium") {
    imageClasses.push(styles.medium);
  }

  if (size === "large") {
    imageClasses.push(styles.large);
  }

  if (tiny) {
    imageClasses.push(styles.tiny);
  }

  if (topGap) {
    imageClasses.push(styles.topGap);
  }

  if (zoomable) {
    imageClasses.push(styles.zoomable);

    return (
      <ZoomableImage
        className={imageClasses.join(" ")}
        post={post}
        originalImage={image}
      />
    );
  }

  return <img src={src} alt={image.alt} className={imageClasses.join(" ")} />;
};

export default EmbeddedImage;
