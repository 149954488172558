import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import usePhase from "utils/usePhase";
import useStore from "utils/useStore";

import Button from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";
import Modal from "components/Modal";
import Bottom from "components/Bottom";
import Previous from "components/Previous";
import CardRow from "components/CardRow";
import { FadeUp } from "animations";

import constants from "constants/fi.json";
import useClothing from "./useClothing";
import useClothingStore from "./useClothingStore";

const Preparation = () => {
  const t = usePhase("preparation");

  const setUserSelection = useStore((state) => state.setUserSelection);
  const calculateTotalCost = useStore((state) => state.calculateCost);

  const [clothing, setClothing, undo] = useClothingStore();

  const [clothings, setOpenModalIndex, openModalIndex, ModalContent] =
    useClothing();

  const closeModal = () => setOpenModalIndex(null);

  useEffect(() => {
    if (clothing === null) {
      setUserSelection("clothing", null);
      return;
    }

    setUserSelection("clothing", clothing);
  }, [clothing, setUserSelection]);

  return (
    <>
      <div className="App-content">
        <Bubble disableAutomaticScroll>{t("preparation_intro")}</Bubble>
        <Bubble delay={0.75} disableAutomaticScroll>
          {t("what_kind_of_clothes")}
        </Bubble>

        <AnimatePresence mode='wait'>
          {openModalIndex !== null && (
            <Modal closeModal={closeModal} childKey={`modal-${openModalIndex}`}>
              <ModalContent setClothing={setClothing} closeModal={closeModal} />
            </Modal>
          )}
        </AnimatePresence>

        {clothing && (
          <>
            <UserBubble>
              {t(
                "user_has_selected_clothing",
                "%selected_clothing%",
                clothing.title
              )}
            </UserBubble>
            <Bubble delay={0.75}>
              {t(
                "thanks_tell_price_and_next",
                "%price%",
                String(calculateTotalCost())
              )}
            </Bubble>
          </>
        )}
      </div>
      <Bottom>
        {!clothing && <CardRow posts={clothings} select={setOpenModalIndex} />}

        {clothing && (
          <FadeUp delay={1.5}>
            <Link to="/kukat">
              <Button>{constants.next}</Button>
            </Link>
          </FadeUp>
        )}

        <Previous to={undo("/arkkuun-valmistelu")} />
      </Bottom>
    </>
  );
};

export default Preparation;
