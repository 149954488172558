import React from "react";

import Button from "components/Button";
import EmbeddedImage from "components/EmbeddedImage";
import ModalNavigation from "components/ModalNavigation";

import styles from "./PackModalContent.module.css";

import constants from "constants/fi.json";
import { WP_REST_API_Post } from "types/WP";

import useStore, { UserSelection } from "utils/useStore";
import analytics from "utils/analytics";

interface Props {
  closeModal: React.MouseEventHandler<HTMLButtonElement>;
  select: (guestFlower: UserSelection) => void;
  flowers: any;
  browse: (direction: 1 | -1) => void;
  coffinFlowerSizes?: WP_REST_API_Post[];
  dontChangeState?: boolean;
}

const Modal = ({
  flowers,
  browse,
  select,
  closeModal,
  dontChangeState,
}: Props) => {
  const setUserSelection = useStore((state) => state.setUserSelection);

  const title = flowers.title.rendered;
  const price = flowers.price;

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.toprow}>
          <span className={[styles.title, "h2"].join(" ")}>{title}</span>
          {price && <span className={styles.price}>, {price} €</span>}
        </div>
      )}
      {flowers.content.rendered && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: flowers.content.rendered }}
        />
      )}

      <EmbeddedImage post={flowers} size="medium" zoomable />

      <Button
        fullWidth
        onClick={(e) => {
          if (!dontChangeState) {
            setUserSelection("guestFlower", {
              id: parseInt(flowers.id),
              title,
              price,
            });

            analytics.pushSelection({
              selectionTitle: "Guest flower",
              selectionValue: title,
            });
          }

          select({
            id: flowers.id,
            title,
            price,
          });

          closeModal(e);
        }}
        topGap
      >
        {constants.choose_and_continue}
      </Button>

      <ModalNavigation browse={browse} />
    </div>
  );
};

export default Modal;
