import React from "react";
import styles from "./Bottom.module.css";

interface Props {
  children: React.ReactNode;
  stackOnDesktop?: boolean;
  floating?: boolean;
}

const Bottom = ({ children, stackOnDesktop, floating }: Props) => {
  const classes = [styles.bottom];

  if (floating) {
    classes.push(styles.floating);
  }

  if (stackOnDesktop) {
    classes.push(styles.stackOnDesktop);
  }

  return (
    <>
      {floating ? <div className={styles.placeholderForFloater}></div> : null}
      <div className={classes.join(" ")}>{children}</div>
    </>
  );
};

export default Bottom;
