import { motion } from "framer-motion";

import styles from "./BackgroundImage.module.css";

const BackgroundImage = ({ cover }: { cover: boolean }) => {
  const opacity = "#c5bbaa";

  const animate = {
    opacity: cover ? "0.05" : "0.2",
  };

  return (
    <div className={styles.wrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2010 1179"
        preserveAspectRatio="xMidYMid slice"
        height="100%"
        width="100%"
      >
        <motion.g
          stroke="#C5BBAA"
          strokeWidth="1.5"
          fill="none"
          fillRule="evenodd"
          opacity={opacity}
          animate={animate}
          initial={animate}
          transition={{ delay: 1 }}
        >
          <path d="M1 604.262V1h310.968v353.717V1h539.174v411.508" />
          <path d="M581.556 76.86c-125.218 0-230.47 85.38-260.783 201.09 124.893 23.527 219.399 133.12 219.399 264.85v635.45h310.97V346.447c0-148.888-120.7-269.587-269.586-269.587Z" />
          <path d="M320.774 277.95a269.928 269.928 0 0 0-8.806 68.497v831.803H540.17V542.8c0-131.73-94.506-241.324-219.397-264.85Z" />
          <path d="m1 1178.25 310.968-259.638V276.454c-13.493-2.097-27.3-3.24-41.381-3.24C121.697 273.213 1 393.911 1 542.8v635.45h310.968V918.612" />
          <g>
            <path d="M1159 604.262V1h310.968v353.717V1h539.174v411.508" />
            <path d="M1739.556 76.86c-125.218 0-230.47 85.38-260.783 201.09 124.893 23.527 219.399 133.12 219.399 264.85v635.45h310.97V346.447c0-148.888-120.7-269.587-269.586-269.587Z" />
            <path d="M1478.774 277.95a269.928 269.928 0 0 0-8.806 68.497v831.803h228.203V542.8c0-131.73-94.506-241.324-219.397-264.85Z" />
            <path d="m1159 1178.25 310.968-259.638V276.454c-13.493-2.097-27.3-3.24-41.381-3.24-148.89 0-269.587 120.698-269.587 269.586v635.45h310.968V918.612" />
          </g>
        </motion.g>
      </svg>
    </div>
  );
};

export default BackgroundImage;
