import React from "react";

import CoverIcon from "components/CoverIcon";

import constants from "constants/fi.json";

const Loading = () => {
  return <CoverIcon src={null} alt={constants.welcome} />;
};

export default Loading;
