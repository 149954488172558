import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { get } from "lodash-es";

import ReactDropdown, { Option } from "react-dropdown";
import { FadeIn } from "animations";

import "react-dropdown/style.css";
import styles from "./Dropdown.module.css";

import constants from "constants/fi.json";
import { SelectableItem } from "utils/useStore";

const searchEventHandler = (event: MouseEvent | TouchEvent) => {
  if (get(event.target, "dataset.type") === "search") {
    event.stopPropagation();
    event.preventDefault();

    if (event.type === "touchend" || event.type === "touchstart") {
      event.stopImmediatePropagation();
      document.getElementById("search-input")?.focus();
    }
  }
};

const filterOptionsByKey = (items: SelectableItem[], key: string) => {
  return items.filter((item) => {
    const keyToLowerCase = key.toLowerCase();
    const keyTrimmed = keyToLowerCase.trim();
    
    const titleToLowerCase = item.title.toLowerCase();

    return titleToLowerCase.includes(keyTrimmed);
  });
};

const formatOptions = (options: SelectableItem[]) => {
  return options.map(
    (option) =>
      ({
        value: String(option.id),
        label: option.title,
      } as Option)
  );
};

const formatOptionToItem = (option: Option) => {
  return {
    id: Number(option.value),
    title: option.label,
  } as SelectableItem;
};

interface Props {
  filled?: boolean;
  bottomGap?: boolean;
  delay?: number;
  summary?: boolean;
  search?: boolean;
  value?: string | Option | undefined;
  onChange: (selected: SelectableItem) => void;
  options: SelectableItem[];
  placeholder?: string;
  disabled?: boolean;
}

const Dropdown = ({
  options,
  onChange,
  placeholder,
  disabled,
  filled,
  bottomGap,
  delay,
  summary,
  value,
  search,
}: Props) => {
  const [searchKey, setSearchKey] = useState<string>("");

  const rootClasses = [styles.root];

  if (disabled) {
    rootClasses.push(styles.disabled);
  }

  if (filled) {
    rootClasses.push(styles.filled);
  }

  if (bottomGap) {
    rootClasses.push(styles.bottomGap);
  }

  if (summary) {
    rootClasses.push(styles.summary);
  }

  const fadeClasses = [];

  if (summary) {
    fadeClasses.push(styles.summaryFade);
  }

  if (search) {
    rootClasses.push(styles.rootSearch);

    document.addEventListener("click", searchEventHandler, true);
    document.addEventListener("touchstart", searchEventHandler, {
      passive: false,
    });
    document.addEventListener("touchend", searchEventHandler, {
      passive: false,
    });

    if (searchKey !== "") {
      options = filterOptionsByKey(options, searchKey);
    }
  }

  return (
    <AnimatePresence mode='wait'>
      <FadeIn delay={delay} className={fadeClasses.join(" ")}>
        <div
          style={
            search
              ? ({
                  "--no-options": `'${constants.no_options_dropdown}'`,
                  position: "relative",
                } as React.CSSProperties)
              : undefined
          }
        >
          <ReactDropdown
            className={rootClasses.join(" ")}
            controlClassName={styles.control}
            menuClassName={styles.menu}
            options={formatOptions(options)}
            onChange={(value) => {
              setSearchKey("");

              if (onChange) {
                onChange(formatOptionToItem(value));
              }
            }}
            placeholder={placeholder}
            arrowClosed={<span className={styles.arrowClosed} />}
            arrowOpen={<span className={styles.arrowOpen} />}
            disabled={disabled}
            value={value}
          />
          {search && (
            <div className={styles.searchRoot}>
              <div className={styles.searchInputWrapper}>
                <input
                  className={styles.searchInput}
                  data-type="search"
                  id="search-input"
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder={constants.find_by_text}
                />
              </div>
            </div>
          )}
        </div>
      </FadeIn>
    </AnimatePresence>
  );
};

export default Dropdown;
