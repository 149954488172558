
const sendOffer = (data: any, callback: (result: any) => void) => {

  let headers = new Headers();

  if (process.env.REACT_APP_API_AUTH) {
    headers.set(
      "Authorization",
      "Basic " + btoa(process.env.REACT_APP_API_AUTH)
    );
  }

  headers.set("Content-Type", "application/json");

  const url = [
    process.env.REACT_APP_API_BASE,
    "wp-json",
    "memoria",
    "v1",
    "submit-request",
  ].join("/");

  callback(data);

  fetch(url, {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((result) => callback(result));
};

export default sendOffer;
