import React, { useEffect } from "react";

import Button from "components/Button";
import BodyText from "components/BodyText";

import usePhase from "utils/usePhase";
import useStore from "utils/useStore";

import styles from "./thanks.module.css";

const Thanks = () => {
  const t = usePhase("thanks");
  const resetUserSelections = useStore((state) => state.resetUserSelections);

  useEffect(() => {
    resetUserSelections();
  }, [resetUserSelections]);

  return (
    <div className={styles.thanks}>
      <h2 className="h1">{t("title")}</h2>
      <BodyText>{t("paragraph")}</BodyText>
      <a href="https://memoria.fi">
        <Button topGap>{t("move_to_memoria_fi")}</Button>
      </a>
    </div>
  );
};

export default Thanks;
