import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Button from "components/Button";
import CoverIcon from "components/CoverIcon";

import usePhase from "utils/usePhase";
import useIcon from "utils/useIcon";

import { routes } from "views";

import constants from "constants/fi.json";
import Previous from "components/Previous";

import Bottom from "components/Bottom";

import styles from "./Cover.module.css";
import useStore from "utils/useStore";

const Cover = ({ name }: { name: string }) => {
  const { pathname } = useLocation();

  const t = usePhase(name);
  const icon = useIcon(name);
  const needsUrnSelection = useStore((state) => state.needsUrnSelection)();

  if (!icon) {
    return null;
  }

  const prevIndex = Math.max(
    routes.findIndex(({ path }) => path === pathname) - 1,
    0
  );

  const nextIndex = Math.min(
    routes.findIndex(({ path }) => path === pathname) + 1,
    routes.length
  );

  // Let's not navigate to urn selection if it is not needed
  const previousTo =
    routes[prevIndex].path === "/uurnan-valinta/vaihe-2" && !needsUrnSelection
      ? "/arkun-valinta/vaihe-2"
      : routes[prevIndex].path;

  return (
    <div className={styles.cover}>
      <div>
        <CoverIcon src={icon} alt={t("title")} />
      </div>
      <div className="App-content">
        <h2 className={["h1", styles.textcolor].join(" ")}>{t("title")}</h2>
        <p className={styles.body}>{t("lead")}</p>
      </div>
      <Bottom>
        <Link to={routes[nextIndex].path}>
          <Button dark uppercase cover>
            {constants.next}
          </Button>
        </Link>
        <Previous spanStyles={styles.textcolor} to={previousTo} />
      </Bottom>
    </div>
  );
};

export default Cover;
