
const fetchUnfinishedRequest = (hash: string, callback: (result: any) => void) => {

  let headers = new Headers();

  if (process.env.REACT_APP_API_AUTH) {
    headers.set(
      "Authorization",
      "Basic " + btoa(process.env.REACT_APP_API_AUTH)
    );
  }

  const params = new URLSearchParams({ hash });

  const url = [
    process.env.REACT_APP_API_BASE,
    "wp-json",
    "memoria",
    "v1",
    "fetch-unfinished-request",
    `?${params.toString()}`,
  ].join("/");

  fetch(url, {
    method: "GET",
    headers,
    credentials: "include",
  })
    .then((res) => res.json())
    .then((result) => callback(result));
};

export default fetchUnfinishedRequest;
