import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { FadeUp } from "animations";

import styles from "./Bubble.module.css";
import useScrollToElement from "utils/useScrollToElement";

interface Props {
  children: React.ReactNode;
  delay?: number | undefined;
  disableAutomaticScroll?: boolean;
}

export const UserBubble = ({ children, delay }: Props) => (
  <div className={styles.userHolder}>
    <AnimatePresence mode='wait'>
      <FadeUp className={[styles.user, styles.default].join(" ")} delay={delay}>
        {children}
      </FadeUp>
    </AnimatePresence>
  </div>
);

const Bubble = ({ children, delay, disableAutomaticScroll }: Props) => {
  const [executeScroll, elRef] = useScrollToElement();

  useEffect(() => {
    if (!disableAutomaticScroll) {
      executeScroll();
    }
  });

  return (
    <AnimatePresence mode='wait'>
      <FadeUp className={styles.default} delay={delay}>
        <div ref={elRef}>{children}</div>
      </FadeUp>
    </AnimatePresence>
  );
};

export default Bubble;
