import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import Button from "components/Button";
import usePhase from "utils/usePhase";

import styles from "./summary.module.css";
import Modal from "components/Modal";

import submitUnfinishedRequest from "utils/submitUnfinishedRequest";
import copyUnfinishedRequestUrl from "utils/copyUnfinishedRequestUrl";

interface ShareSummaryProps {
  data: Record<string, any>;
}

const ShareSummary = ({ data }: ShareSummaryProps) => {
  const t = usePhase("summary");

  const [linkHasBeenCopied, setLinkHasBeenCopied] = useState(false);
  const [errorWithCopy, setErrorWithCopy] = useState(false);

  return (
    <>
      <div className={styles.shareSummary}>
        <div className={styles.shareSummaryLeft}>
          {t("share_summary")}
          <Button
            icon="share-link"
            onClick={() => {
              copyUnfinishedRequestUrl(data, (result) => {
                if (result.success) {
                  setLinkHasBeenCopied(true);

                  setTimeout(() => {
                    setLinkHasBeenCopied(false);
                  }, 3000);
                } else {
                  setErrorWithCopy(true);
                }
              });
            }}
          >
            {t("copy_link_to_share")}
          </Button>
          <Button
            icon="whatsapp"
            onClick={() => {
              // ref: https://stackoverflow.com/a/39387533

              const windowReference = window.open();

              submitUnfinishedRequest(data, (result) => {
                if (result.success) {
                  const url = `${window.location.origin}/yhteenveto/${result.hash}`;
                  const waUrl = `https://wa.me/?text=${encodeURIComponent(
                    `${t("whatsapp_text_prefix")} ${url}`
                  )}`;

                  if (windowReference) {
                    windowReference.location = waUrl;
                  }
                } else {
                  setErrorWithCopy(true);
                }
              });
            }}
          >
            {t("share_on_whatsapp")}
          </Button>
        </div>
        <div className={styles.shareSummaryRight}>
          {t("share_link_description")}
        </div>
      </div>
      <AnimatePresence>
        {linkHasBeenCopied && (
          <Modal
            closeModal={() => {
              setLinkHasBeenCopied(false);
            }}
          >
            <span className="h2">{t("copied")}</span>
          </Modal>
        )}
        {errorWithCopy && (
          <Modal
            closeModal={() => {
              setErrorWithCopy(false);
            }}
          >
            <span className="h2">{t("error_with_copying_link")}</span>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default ShareSummary;
