import { get } from "lodash-es";

import useStore from "utils/useStore";

const useIcon = (section: string) => {
  const icons = useStore((state) => state.icons);
  const icon = get(icons, section, null);

  return icon ? String(icon) : null;
};

export default useIcon;
