import { useNavigate } from "react-router-dom";
import useStore, { UserSelection } from "utils/useStore";

const useClothingStore = () => {
  const clothing = useStore((state) => state.clothing);
  const setUserSelection = useStore((state) => state.setUserSelection);
  const navigate = useNavigate();

  const setClothing = (clothing: UserSelection) =>
    setUserSelection("clothing", clothing);

  const undo = (previousPath: string) => () => {
    if (clothing) {
      setUserSelection("clothing", null);
    } else if (previousPath) {
      navigate(previousPath);
    }
  };

  return [clothing, setClothing, undo] as const;
};

export default useClothingStore;
