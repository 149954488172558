import { useState, useEffect } from "react";
import { WP_REST_API_Post } from "types/WP";

export const useData = (
  path: string | null,
  namespace: "wp" | "memoria" = "wp",
  version: "v1" | "v2" = "v2"
) => {
  const [result, setResult] = useState<WP_REST_API_Post[]>();

  const url = path
    ? [
        process.env.REACT_APP_API_BASE,
        "wp-json",
        namespace,
        version,
        path,
      ].join("/")
    : null;

  useEffect(() => {
    if (!url) {
      return;
    }
    let headers = new Headers();

    if (process.env.REACT_APP_API_AUTH) {
      headers.set(
        "Authorization",
        "Basic " + btoa(process.env.REACT_APP_API_AUTH)
      );
    }

    fetch(url, { method: "GET", headers, credentials: "include" })
      .then((res) => res.json())
      .then((result) => setResult(result));
  }, [url]);

  return result;
};

const getData = (
  callback: (result: any) => void,
  path: string,
  namespace: "wp" | "memoria" = "wp",
  version: "v1" | "v2" = "v2"
) => {
  let headers = new Headers();

  if (process.env.REACT_APP_API_AUTH) {
    headers.set(
      "Authorization",
      "Basic " + btoa(process.env.REACT_APP_API_AUTH)
    );
  }

  const url = [
    process.env.REACT_APP_API_BASE,
    "wp-json",
    namespace,
    version,
    path,
  ].join("/");

  fetch(url, { method: "GET", headers, credentials: "include" })
    .then((res) => res.json())
    .then((result) => callback(result));
};

export default getData;
