import { get } from "lodash-es";

import useStore from "utils/useStore";

const usePhase = (section: string) => {
  const phases = useStore((state) => state.phases);

  return (key: string, search?: string, replace?: string) => {
    const result = String(get(phases, [section, key], ''));

    if (result && search && replace) {
      return result.replace(search, replace);
    }

    return result;
  };
};

export default usePhase;
