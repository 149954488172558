import React from "react";

import source from "images/external-link.svg";
import styles from "./ExternalLinkSymbol.module.css";
import constants from "constants/fi.json";

const ExternalLinkSymbol = () => (
  <img src={source} alt={constants.link_opens_new_tab} className={styles.external} />
);

export default ExternalLinkSymbol;
