import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Button, { Buttons } from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";

import usePhase from "utils/usePhase";
import { useData } from "utils/getData";
import analytics from "utils/analytics";

import { FadeUp } from "animations";

import constants from "constants/fi.json";
import useStore from "utils/useStore";
import Previous from "components/Previous";
import Bottom from "components/Bottom";
import useExtraTransportation from "./useExtraTransportation";
import useBurialStore from "./useBurialStore";

const EMPTY_BURIAL = {
  id: 0,
  title: constants.skip,
};

const Burial = () => {
  const burials = useData("burial") as any;
  const t = usePhase("burial");

  const mappedBurials = Array.isArray(burials)
    ? burials.map(({ id, name }) => {
        return { id, title: name };
      })
    : [];

  if (mappedBurials) {
    mappedBurials.push(EMPTY_BURIAL);
  }

  const [burial, setBurial, undo] = useBurialStore();

  const setUserSelection = useStore((state) => state.setUserSelection);

  useEffect(() => {
    if (burial === null) {
      setUserSelection("burial", null);

      return;
    }

    setUserSelection("burial", burial);

    analytics.pushSelection({
      selectionTitle: "Burial type",
      selectionValue: String(burial.title),
    });
  }, [burial, setUserSelection]);

  useExtraTransportation(burial);

  return (
    <>
      <div className="App-content">
        <Bubble disableAutomaticScroll>{t("what_type")}</Bubble>
        {burial && (
          <>
            <UserBubble>
              {burial.title === EMPTY_BURIAL.title
                ? t("user_has_skipped_burial")
                : t("it_is_type", "%type%", `${burial.title}`)}
            </UserBubble>
            <Bubble delay={0.75}>{t("ok_next_coffin")}</Bubble>
          </>
        )}
      </div>

      <Bottom>
        {!burial && (
          <Buttons>
            {mappedBurials.map((burial, index) => (
              <FadeUp delay={0.75 + 0.5 * index} key={index}>
                <Button
                  key={burial.id}
                  onClick={() => setBurial(burial)}
                  secondary={burial.title === EMPTY_BURIAL.title}
                >
                  {burial.title}
                </Button>
              </FadeUp>
            ))}
          </Buttons>
        )}

        {burial && (
          <Link to="/arkun-valinta">
            <FadeUp delay={1.5}>
              <Button>{constants.next}</Button>
            </FadeUp>
          </Link>
        )}

        <Previous to={undo("/hautaustapa")} />
      </Bottom>
    </>
  );
};

export default Burial;
