import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import usePhase from "utils/usePhase";
import useStore from "utils/useStore";

import Button from "components/Button";
import Bubble, { UserBubble } from "components/Bubble";
import Modal from "components/Modal";
import CardRow from "components/CardRow";
import Previous from "components/Previous";
import Bottom from "components/Bottom";

import { FadeIn } from "animations";

import useGuestFlowers from "./useGuestFlowers";
import useCoffinFlowers from "./useCoffinFlowers";
import useFlowersStore from "./useFlowersStore";

import constants from "constants/fi.json";

const EMPTY_COFFIN_FLOWER = {
  id: 0,
  title: "Ei arkkulaitetta",
  price: "0",
};

const EMPTY_GUEST_FLOWER = {
  id: 0,
  title: "Ei laskettavia kukkia",
  price: "0",
};

const Flowers = () => {
  const t = usePhase("flowers");

  const [
    coffinFlower,
    setCoffinFlower,
    coffinFlowerSizes,
    setCoffinFlowerSizes,
    guestFlower,
    setGuestFlower,
    undo,
  ] = useFlowersStore();

  const setEmptyCoffinFlower = () => {
    setUserSelection("coffinFlower", EMPTY_COFFIN_FLOWER);
    setCoffinFlower(EMPTY_COFFIN_FLOWER);
  };

  const setEmptyGuestFlower = () => {
    setUserSelection("guestFlower", EMPTY_GUEST_FLOWER);
    setGuestFlower(EMPTY_GUEST_FLOWER);
  };

  const [priceBeforeGuestFlower, setPriceBeforeGuestFlower] =
    useState<number>(0);

  const [
    guestFlowers,
    setGuestFlowerIndex,
    guestFlowerIndex,
    GuestFlowerModalContent,
  ] = useGuestFlowers();

  const [
    coffinFlowers,
    setCoffinFlowerIndex,
    coffinFlowerIndex,
    CoffinFlowerModalContent,
  ] = useCoffinFlowers();

  const closeCoffinModal = () => setCoffinFlowerIndex(null);
  const closeGuestModal = () => setGuestFlowerIndex(null);

  const setUserSelection = useStore((state) => state.setUserSelection);

  const calculateTotalCost = useStore((state) => state.calculateCost);

  useEffect(() => {
    if (coffinFlower !== null) {
      setUserSelection("coffinFlower", coffinFlower);
    }

    if (guestFlower !== null) {
      setUserSelection("guestFlower", guestFlower);
    }
  });

  useEffect(() => {
    if (coffinFlower && guestFlower === null) {
      setPriceBeforeGuestFlower(calculateTotalCost());
    }
  }, [coffinFlower, guestFlower, calculateTotalCost]);

  return (
    <>
      <div className="App-content">
        {coffinFlowers.length > 0 && (
          <>
            <Bubble>
              {t(
                "coffin_flowers_what_is_best",
                "%amount%",
                String(coffinFlowers.length)
              )}
            </Bubble>
          </>
        )}

        <AnimatePresence mode='wait'>
          {coffinFlowerIndex !== null && (
            <Modal
              closeModal={closeCoffinModal}
              childKey={`modal-${coffinFlowerIndex}`}
            >
              <CoffinFlowerModalContent
                setCoffinFlowerSize={setCoffinFlowerSizes}
                setCoffinFlower={setCoffinFlower}
                closeModal={closeCoffinModal}
                selectedSize={coffinFlowerSizes}
              />
            </Modal>
          )}
        </AnimatePresence>

        <AnimatePresence mode='wait'>
          {guestFlowerIndex !== null && (
            <Modal
              closeModal={closeGuestModal}
              childKey={`modal-${guestFlowerIndex}`}
            >
              <GuestFlowerModalContent
                setGuestFlower={setGuestFlower}
                closeModal={closeGuestModal}
              />
            </Modal>
          )}
        </AnimatePresence>

        {coffinFlower !== null && (
          <>
            <UserBubble>
              {t(
                "user_has_selected_coffin_flowers",
                "%selected_coffin_flower%",
                coffinFlower.title === EMPTY_COFFIN_FLOWER.title
                  ? coffinFlower.title.toLowerCase()
                  : coffinFlower.title
              )}
            </UserBubble>
            <Bubble delay={0.75}>
              {t(
                "thanks_tell_price_next_guest_flowers",
                "%price%",
                String(priceBeforeGuestFlower)
              )}
            </Bubble>
          </>
        )}
        {guestFlower !== null && (
          <>
            <UserBubble>
              {t(
                "user_has_selected_guest_flower",
                "%selected_guest_flower%",
                guestFlower.title === EMPTY_GUEST_FLOWER.title
                  ? guestFlower.title.toLowerCase()
                  : guestFlower.title
              )}
            </UserBubble>
            <Bubble delay={0.75}>
              {t(
                "thanks_tell_price_next_happening",
                "%price%",
                String(calculateTotalCost())
              )}
            </Bubble>
          </>
        )}
      </div>
      <Bottom>
        {coffinFlower === null && (
          <>
            <CardRow posts={coffinFlowers} select={setCoffinFlowerIndex} />

            <Button secondary topGap onClick={setEmptyCoffinFlower}>
              {constants.no_coffin_flower}
            </Button>
          </>
        )}

        {coffinFlower !== null && guestFlower === null && (
          <>
            <CardRow posts={guestFlowers} select={setGuestFlowerIndex} />
            <Button secondary topGap onClick={setEmptyGuestFlower}>
              {constants.no_guest_flower}
            </Button>
          </>
        )}

        {guestFlower !== null && (
          <FadeIn delay={1.5}>
            <Link to="/yhteenveto">
              <Button>{constants.next}</Button>
            </Link>
          </FadeIn>
        )}

        <Previous to={undo("/kukat")} />
      </Bottom>
    </>
  );
};

export default Flowers;
