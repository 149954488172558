import { useData } from "utils/getData";
import useStore from "utils/useStore";

const useMappedCoffinPrices = () => {
  const storedAgency = useStore((state) => state.agency);

  const coffins = useData("coffin/?_embed");
  const agency = useData(`agency/${storedAgency}`) as any;
  const coffinOptions = useData("coffin-option");

  if (!Array.isArray(coffins) || !Array.isArray(coffinOptions) || !agency) {
    return [];
  }

  const agencyCoffins = agency && agency.acf ? agency.acf.agency_coffins : null;

  if (!agencyCoffins) {
    return [];
  }

  const mappedCoffins = agencyCoffins
    .map((agencyCoffin: any) => {
      const mappedCoffin = coffins.find(
        (coffin: any) => coffin.id === agencyCoffin.coffin
      );

      if (!mappedCoffin) {
        return null;
      }

      mappedCoffin.price = agencyCoffin.price.price_base;
      mappedCoffin.options = agencyCoffin.price.option_prices
        ? agencyCoffin.price.option_prices.map((optPrice: any) => {
            const mappedOption = coffinOptions.find(
              (option: any) => option.id === optPrice.option
            );

            if (!mappedOption) {
              return null;
            }

            return {
              id: mappedOption.id,
              title: `${mappedOption.name} (+ ${optPrice.price} €)`,
              price: optPrice.price,
            };
          })
        : [];

      return mappedCoffin;
    })
    .filter((coffin: any) => !!coffin);

  return Array.isArray(mappedCoffins) ? mappedCoffins : [];
};

export default useMappedCoffinPrices;
