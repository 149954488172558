import React, { useState } from "react";
import { Link } from "react-router-dom";

import Button from "components/Button";
import CoverIcon from "components/CoverIcon";
import Bottom from "components/Bottom";

import usePhase from "utils/usePhase";

import constants from "constants/fi.json";
import useIcon from "utils/useIcon";

import styles from "./intro.module.css";
import BodyText from "components/BodyText";

const Intro = () => {
  const [approval, setApproval] = useState(false);
  const icon = useIcon("intro");
  const t = usePhase("intro");

  return (
    <>
      <CoverIcon src={icon} alt={t("title")} />
      <span className={styles.title}>{t("title")}</span>

      <div className={styles.content}>
        <h2 className="h1">{t("subtitle")}</h2>
        <BodyText>{t("lead")}</BodyText>
        <BodyText className={styles.tac}>
          <input
            id="tac"
            type="checkbox"
            name="terms-and-conditions"
            onChange={(e) => setApproval(e.target.checked)}
            checked={approval}
          />
          <label htmlFor="tac">{t("terms_and_conditions_agreement")}</label>
          <div>
            <a href="/tietosuojaseloste" target="_blank">
              {t("read_terms_and_conditions_agreement")}
            </a>
          </div>
        </BodyText>
      </div>
      <Bottom>
        <div style={{ marginBottom: "4.625rem" }}>
          <Link to={approval ? "/kuljetukset" : ""}>
            <Button disabled={!approval} uppercase cover>
              {constants.next}
            </Button>
          </Link>
        </div>
      </Bottom>
    </>
  );
};

export default Intro;
