import React, { useState } from "react";
import { UserSelection } from "utils/useStore";

import GuestFlowersModalContent from "./GuestFlowersModalContent";
import useMappedGuestFlowerPrices from "./useMappedGuestFlowerPrices";

const useGuestFlowers = () => {
  const flowers = useMappedGuestFlowerPrices();
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);

  const ModalContent = ({
    closeModal,
    setGuestFlower,
    dontChangeState,
  }: {
    closeModal: React.MouseEventHandler<HTMLButtonElement>;
    setGuestFlower: (guestFlower: UserSelection) => void;
    dontChangeState?: boolean;
  }) => (
    <>
      {openModalIndex !== null && (
        <GuestFlowersModalContent
          flowers={flowers[openModalIndex]}
          closeModal={closeModal}
          select={setGuestFlower}
          dontChangeState={dontChangeState}
          browse={(newDirection: 1 | -1) => {
            const newIndex = openModalIndex + newDirection;
            if (newIndex >= 0 && newIndex < flowers.length) {
              setOpenModalIndex(newIndex);
            }
          }}
        />
      )}
    </>
  );

  return [flowers, setOpenModalIndex, openModalIndex, ModalContent] as const;
};

export default useGuestFlowers;
