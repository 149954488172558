import { useState, useEffect } from "react";
import getData from "utils/getData";
import useStore, { UserSelection } from "utils/useStore";
import { MAX_ALLOWED_DISTANCE } from "utils/apiCodes";

type TransportationResult = {
  code?: string;
  message?: string;
  data?: unknown;
  cost?: {
    total: string;
    extra: boolean;
  };
};

const useTransportationCost = (
  hospital: UserSelection,
  blessingPlace: UserSelection,
  targetMunicipality: UserSelection,
  skipBlessingPlace: boolean | null,
) => {
  const agency = useStore((state) => state.agency);

  const [transportDisallowed, setTransportDisallowed] =
    useState<boolean>(false);
  const [cost, setCost] = useState<string | null>(null);

  useEffect(() => {
    if (!hospital || !blessingPlace || !agency || !targetMunicipality) {
      setTransportDisallowed(false);
      setCost(null);
      return;
    }

    const params = {
      agency: `${agency}`,
      hospital: `${hospital.id}`,
      blessing_place: `${blessingPlace.id}`,
      include_extra: "0",
    } as Record<string, string>;

    if ( skipBlessingPlace ) {
      params['blessing_place_municipality'] = String(targetMunicipality.id);
    }

    const paramsStr = new URLSearchParams(params).toString();

    getData(
      (result: TransportationResult) => {
        if (result.code === MAX_ALLOWED_DISTANCE) {
          setTransportDisallowed(true);
          setCost(null);
        } else if (result.cost) {
          setTransportDisallowed(false);
          setCost(result.cost.total);
        }
      },
      `transportation/?${paramsStr}`,
      "memoria",
      "v1"
    );
  }, [hospital, blessingPlace, agency, targetMunicipality, skipBlessingPlace]);

  return [cost, transportDisallowed] as const;
};

export default useTransportationCost;
