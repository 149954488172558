// @ts-nocheck
import React, { useRef, useState } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import styles from "./CardRow.module.css";

import { FadeUp } from "animations";
import Card from "./Card";
import { WP_REST_API_Post } from "types/WP";

interface Post extends WP_REST_API_Post {
  price?: string;
}

interface Props {
  select: React.Dispatch<React.SetStateAction<number | null>>;
  posts: Post[];
}

const CardRow = ({ select, posts }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  let carouselRef = useRef(null);

  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  if (!posts) {
    return null;
  }

  const items = posts.map((post: Post, index: number) => {
    return (
      <Card
        key={post.id}
        post={post}
        onClick={() => {
          select(index);
          setActiveIndex(activeIndex);
        }}
      />
    );
  });

  const responsive = {
    568: { items: 2 },
    1024: { items: 4 },
  };

  return (
    <FadeUp delay={1}>
      <div className={styles.cardrow} data-amount={items.length}>
        <div
          className={styles.prev}
          onClick={() => carouselRef.slidePrev()}
        ></div>
        <AliceCarousel
          items={items}
          responsive={responsive}
          ref={(el) => (carouselRef = el)}
          disableButtonsControls
          activeIndex={activeIndex}
          onSlideChanged={syncActiveIndex}
          disableDotsControls
        />
        <div
          className={styles.next}
          onClick={() => carouselRef.slideNext()}
        ></div>
      </div>
    </FadeUp>
  );
};

export default CardRow;
