import React from "react";

import styles from "./summary.module.css";
import pen from "images/edit-pen.svg";

import constants from "constants/fi.json";

interface Props {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isEditing: boolean;
}

const Edit = ({ onClick, isEditing }: Props) => {
  const classes = [styles.edit];

  if (isEditing) {
    classes.push(styles.isEditing);
  }

  return (
    <div className={classes.join(" ")} onClick={onClick}>
      <img src={pen} alt="" className={styles.pen} />
      <span>{constants.edit}</span>
    </div>
  );
};

export default Edit;
