import { useData } from "utils/getData";
import useStore from "utils/useStore";

const useMappedCoffinFlowerPrices = () => {
  const storedAgency = useStore((state) => state.agency);

  const agency = useData(`agency/${storedAgency}`) as any;
  const flowers = useData("coffin-flower/?_embed");
  const sizes = useData("flower-size");

  if (!Array.isArray(flowers) || !Array.isArray(sizes) || !agency) {
    return [];
  }

  const agencyCoffinFlowers =
    agency && agency.acf ? agency.acf.agency_coffin_flowers : null;

  if (!agencyCoffinFlowers) {
    return [];
  }

  const mappedCoffinFlowers = agencyCoffinFlowers
    .map((agencyCoffinFlower: any) => {
      const mappedCoffinFlower = flowers.find(
        (flower: any) => flower.id === agencyCoffinFlower.coffin_flowers
      );

      if (!mappedCoffinFlower) {
        return null;
      }

      mappedCoffinFlower.price = agencyCoffinFlower.price.price_base;
      mappedCoffinFlower.sizes = agencyCoffinFlower.price.flower_size_prices
        ? agencyCoffinFlower.price.flower_size_prices.map((sizePrice: any) => {
            const mappedOption = sizes.find(
              (size: any) => size.id === sizePrice.option
            );

            if (!mappedOption) {
              return null;
            }

            return {
              id: mappedOption.id,
              title: `${mappedOption.name} (+ ${sizePrice.price} €)`,
              price: `${sizePrice.price}`,
            };
          })
        : [];

      return mappedCoffinFlower;
    })
    .filter((flowers: any) => !!flowers);

  return Array.isArray(mappedCoffinFlowers) ? mappedCoffinFlowers : [];
};

export default useMappedCoffinFlowerPrices;
