import React from "react";
import Button from "components/Button";

import EmbeddedImage from "components/EmbeddedImage";

import styles from "./PackModalContent.module.css";

import constants from "constants/fi.json";
import ModalNavigation from "components/ModalNavigation";
import useStore, { UserSelection } from "utils/useStore";
import analytics from "utils/analytics";

interface Props {
  closeModal: React.MouseEventHandler<HTMLButtonElement>;
  setClothing: (clothing: UserSelection) => void;
  clothing: any;
  browse: (direction: 1 | -1) => void;
  dontChangeState?: boolean;
}

const Modal = ({
  clothing,
  browse,
  setClothing,
  closeModal,
  dontChangeState,
}: Props) => {
  const setUserSelection = useStore((state) => state.setUserSelection);
  const price = clothing.price;
  const title = clothing.title.rendered;

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.toprow}>
          <span className={[styles.title, "h2"].join(" ")}>{title}</span>
          {price && <span className={styles.price}>, {price} €</span>}
        </div>
      )}
      {clothing.content.rendered && (
        <div className={styles.contentwrapper}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: clothing.content.rendered }}
          />
        </div>
      )}
      <EmbeddedImage topGap post={clothing} size="medium" />
      <Button
        topGap
        onClick={(e) => {
          if (!dontChangeState) {
            setUserSelection("clothing", {
              id: parseInt(clothing.id),
              title,
              price,
            });

            analytics.pushSelection({
              selectionTitle: "Clothing",
              selectionValue: title,
            });
          }

          setClothing({ id: clothing.id, title, price });

          closeModal(e);
        }}
        fullWidth
      >
        {constants.choose_and_continue}
      </Button>
      <ModalNavigation browse={browse} />
    </div>
  );
};

export default Modal;
