import React from "react";

import styles from "./CoverIcon.module.css";

const CoverIcon = ({ src, alt }: { src: string | null; alt: string }) => {
  if (!src) {
    return <div className={styles.placeholder} />;
  }

  return (
    <div className={styles.icon}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default CoverIcon;
