import React from "react";

import styles from "./CheckboxList.module.css";
import Checkbox from "./Checkbox";
import { SelectableItem } from "utils/useStore";

interface Props {
  title?: string;
  noOptionTitle?: string;
  selected: SelectableItem[] | null;
  options: SelectableItem[];
  radio?: boolean;
  select: (item: SelectableItem) => void;
  row?: boolean;
}

const CheckboxList = ({
  title,
  selected,
  select,
  options,
  radio,
  row,
}: Props) => {
  if (!options.length) {
    return null;
  }

  const isFilled = (item: SelectableItem) => {
    if (!selected) {
      return false;
    }

    return !!selected.find(
      (selectedItem: SelectableItem) => selectedItem.id === item.id
    );
  };

  const holderClasses = row ? [styles.row] : [];

  return (
    <div>
      {title && <div className={["h4", styles.title].join(" ")}>{title}</div>}
      <div className={holderClasses.join(" ")}>
        {options.map((item) => (
          <Checkbox
            key={item.id}
            filled={isFilled(item)}
            onClick={() => select(item)}
            radio={radio}
          >
            {item.title}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default CheckboxList;
