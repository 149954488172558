import { useState, useEffect } from "react";

import getData from "utils/getData";
import useStore from "utils/useStore";

import { WP_REST_API_Post } from "types/WP";

const useOrderAgency = (municipalityId: number | undefined) => {
  const setAgency = useStore((state) => state.setAgency);
  const storedAgencyIsForced = useStore((state) => state.agencyIsForced);

  const [noAgencyAvailable, setNoAgencyAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (storedAgencyIsForced || !municipalityId) {
      return;
    }

    getData(
      (result: WP_REST_API_Post) => {
        if (result.id) {
          setAgency(result.id);
          setNoAgencyAvailable(false);
        } else if (result.id === null) {
          setNoAgencyAvailable(true);
          setAgency(null);
        }
      },
      `order-agency/${municipalityId}/?_embed`,
      "memoria",
      "v1"
    );
  }, [municipalityId, storedAgencyIsForced, setAgency]);

  return [noAgencyAvailable] as const;
};

export default useOrderAgency;
