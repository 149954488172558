import React from "react";

import Dropdown from "components/Dropdown";

import { useData } from "utils/getData";
import usePhase from "utils/usePhase";
import { SelectableItem } from "utils/useStore";
import Button, { Buttons } from "components/Button";

import constants from "constants/fi.json";

const SkippableDropdown = ({
  municipality,
  path,
  select,
  selectShadow,
  onSkip
}: {
  municipality: SelectableItem | null;
  path: "hospital" | "blessing-place";
  select: (selected: SelectableItem) => void;
  selectShadow?: (selected: SelectableItem) => void;
  onSkip?: () => void;
}) => {
  const optionsData = useData(
    municipality ? `${path}?per_page=100&municipality=${municipality.id}` : null
  );
  const t = usePhase("transport");

  const placeholder =
    path === "hospital" ? t("select_hospital") : t("select_blessing_place");

  const mappedOptions =
    Array.isArray(optionsData) && municipality
      ? optionsData
          .filter(
            (option: any) =>
              Array.isArray(option.municipality) &&
              option.municipality.includes(municipality.id)
          )
          .map(({ title, id }) => {
            return { title: title.rendered, id };
          })
          .sort((a, b) => a.title.localeCompare(b.title, "fi"))
      : [];

  return (
    <Buttons stack>
      <Dropdown
        options={mappedOptions}
        onChange={select}
        placeholder={placeholder}
        disabled={mappedOptions.length < 1}
      />
      {selectShadow && mappedOptions.length > 0 && (
        <Button secondary onClick={() => selectShadow(mappedOptions[0])}>
          {constants.i_dont_know}
        </Button>
      )}
      {onSkip && (
        <Button secondary onClick={() => onSkip()}>
          {constants.i_dont_know}
        </Button>
      )}
    </Buttons>
  );
};

export default SkippableDropdown;
