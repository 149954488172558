import React from "react";

import { BrowserRouter } from "react-router-dom";

import Views from "./views";
import CookieBanner from "components/CookieBanner";

import "./typography.css";
import "./App.css";

function App() {
  return (
      <BrowserRouter>
        <Views />
        <CookieBanner />
      </BrowserRouter>
  );
}

export default App;
