import { useState } from "react";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useStore, { SelectableItem } from "utils/useStore";
import validate from "utils/validate";
import DropdownComponent from "components/Dropdown";

import { useReligions } from "views/_contact";

interface InputProps {
  value: string | null;
  setValue: (value: string) => void;
  placeholder?: string;
  validate?: (value: string) => boolean;
}

interface DropdownProps {
  setValue: (value: SelectableItem) => void;
  value: SelectableItem | null;
  options: SelectableItem[];
  placeholder?: string;
}

const Input = ({ value, setValue, placeholder, validate }: InputProps) => {
  const [isValid, setIsValid] = useState<boolean>(
    validate ? validate(value || "") : true
  );

  const classes = [styles.editingValue];

  if (!isValid) {
    classes.push(styles.error);
  }

  return (
    <input
      className={classes.join(" ")}
      placeholder={placeholder}
      onChange={(e) => {
        if (validate && !validate(e.target.value)) {
          setValue("");
          setIsValid(false);
        } else {
          setValue(e.target.value);
          setIsValid(true);
        }
      }}
      defaultValue={value || undefined}
    />
  );
};

const Dropdown = ({ setValue, value, options, placeholder }: DropdownProps) => {
  return (
    <DropdownComponent
      options={options}
      onChange={(selected) => {
        setValue(selected);
      }}
      placeholder={placeholder}
      value={value?.title}
      summary
    />
  );
};

const Contact = () => {
  const t = usePhase("summary");
  const deceasedName = useStore((state) => state.deceasedName);
  const deceasedSocialId = useStore((state) => state.deceasedSocialId);
  const deceasedChurch = useStore((state) => state.deceasedChurch);
  const deceasedWishes = useStore((state) => state.deceasedWishes);
  const ordererName = useStore((state) => state.ordererName);
  const ordererPhone = useStore((state) => state.ordererPhone);
  const ordererEmail = useStore((state) => state.ordererEmail);
  const ordererRelationship = useStore((state) => state.ordererRelationship);

  const religions = useReligions();

  const setUserSelection = useStore((state) => state.setUserSelection);

  const setDecasedName = (value: string) =>
    setUserSelection("deceasedName", value);
  const setDecasedSocialId = (value: string) =>
    setUserSelection("deceasedSocialId", value);
  const setDeceasedChurch = (value: SelectableItem) =>
    setUserSelection("deceasedChurch", value);
  const setDeceasedWishes = (value: string) =>
    setUserSelection("deceasedWishes", value);

  const setOrdererName = (value: string) =>
    setUserSelection("ordererName", value);
  const setOrdererEmail = (value: string) =>
    setUserSelection("ordererEmail", value);
  const setOrderedPhone = (value: string) =>
    setUserSelection("ordererPhone", value);
  const setOrdererRelationship = (value: string) =>
    setUserSelection("ordererRelationship", value);

  return (
    <div className={styles.block}>
      <div className={styles.subtitle}>
        <h3 className="h3">{t("contact")}</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.subblocktitle}>{t("deceased")}:</div>
        <div className={styles.row}>
          <div className={styles.key}>{t("name")}</div>
          <Input value={deceasedName} setValue={setDecasedName} />
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{t("social_id")}</div>
          <Input value={deceasedSocialId} setValue={setDecasedSocialId} />
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{t("church")}</div>
          <Dropdown
            setValue={setDeceasedChurch}
            options={religions}
            placeholder={t("select_religion")}
            value={deceasedChurch}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{t("deceased_wishes")}</div>
          <Input value={deceasedWishes} setValue={setDeceasedWishes} />
        </div>

        <div className={styles.subblocktitle}>{t("orderer")}:</div>
        <div className={styles.row}>
          <div className={styles.key}>{t("name")} *</div>
          <Input
            setValue={setOrdererName}
            value={ordererName}
            placeholder={t("remember_orderer_name")}
            validate={(value) => value !== ""}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{t("email")} *</div>
          <Input
            setValue={setOrdererEmail}
            value={ordererEmail}
            placeholder={t("remember_orderer_email")}
            validate={validate.email}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{t("phone")}</div>
          <Input setValue={setOrderedPhone} value={ordererPhone} />
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{t("relationship")}</div>
          <Input
            setValue={setOrdererRelationship}
            value={ordererRelationship}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
