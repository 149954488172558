import { get } from "lodash-es";

import { WP_REST_API_Post } from "types/WP";

type size = "medium" | "thumbnail" | "full" | "large";

interface Props extends WP_REST_API_Post {
  _embedded?: any;
}

const parseEmbeddedImage = (post: Props, size: size = "medium") => {
  let imageSource = get(
    post,
    [
      "_embedded",
      "wp:featuredmedia",
      0,
      "media_details",
      "sizes",
      size,
      "source_url",
    ],
    null
  );

  if (!imageSource) {
    return null;
  }

  if (process.env.REACT_APP_API_AUTH) {
    imageSource = imageSource.replace(
      "://",
      `://${process.env.REACT_APP_API_AUTH}@`
    );
  }

  const caption = String(
    get(post, ["_embedded", "wp:featuredmedia", 0, "caption", "rendered"], "")
  );

  return {
    src: String(imageSource),
    alt: caption,
  };
};

export default parseEmbeddedImage;
