import { useData } from "utils/getData";
import useStore from "utils/useStore";

const useMappedUrnPrices = () => {
  const storedAgency = useStore((state) => state.agency);

  const urns = useData("urn/?_embed&per_page=99");
  const agency = useData(`agency/${storedAgency}`) as any;

  if (!Array.isArray(urns) || !agency) {
    return [];
  }

  const agencyUrns = agency && agency.acf ? agency.acf.agency_urns : null;

  if (!agencyUrns) {
    return [];
  }

  const mappedUrns = agencyUrns.map((agencyUrn: any) => {
    const mappedUrn = urns.find((urn: any) => urn.id === agencyUrn.urn);

    if (!mappedUrn) {
      return null;
    }

    mappedUrn.price = agencyUrn.price;

    return mappedUrn;
  });

  return Array.isArray(mappedUrns) ? mappedUrns : [];
};

export default useMappedUrnPrices;
