import { useNavigate } from "react-router-dom";
import useStore, { UserSelection } from "utils/useStore";

import constants from "constants/fi.json";

const EMPTY_BLESSING_PLACE = {
  id: 0,
  title: constants.i_dont_know,
};

const useTransportationStore = () => {
  const setUserSelection = useStore((state) => state.setUserSelection);
  const navigate = useNavigate();

  const currentMunicipality = useStore((state) => state.currentMunicipality);
  const setCurrentMunicipality = (currentMunicipality: UserSelection) =>
    setUserSelection("currentMunicipality", currentMunicipality);

  const hospital = useStore((state) => state.hospital);
  const setHospital = (hospital: UserSelection) =>
    setUserSelection("hospital", hospital);

  const shadowHospital = useStore((state) => state.shadowHospital);
  const setShadowHospital = (shadowHospital: UserSelection) =>
    setUserSelection("shadowHospital", shadowHospital);

  const targetMunicipality = useStore((state) => state.targetMunicipality);
  const setTargetMunicipality = (targetMunicipality: UserSelection) =>
    setUserSelection("targetMunicipality", targetMunicipality);

  const blessingPlace = useStore((state) => state.blessingPlace);
  const setBlessingPlace = (blessingPlace: UserSelection) => {
    setUserSelection("blessingPlace", blessingPlace);
    setUserSelection("skipBlessingPlace", false);
  }

  const attendTransport = useStore((state) => state.attendTransport);
  const setAttendTransport = (attendTransport: boolean | null) =>
    setUserSelection("attendTransport", attendTransport);

  const skipBlessingPlace = useStore((state) => state.skipBlessingPlace);
  const setSkipBlessingPlace = (skipBlessingPlace: boolean | null) =>
    setUserSelection("skipBlessingPlace", skipBlessingPlace);

  const setEmptyBlessingPlace = () => {
    setUserSelection("blessingPlace", EMPTY_BLESSING_PLACE);
    setSkipBlessingPlace(true);
  };

  const undo = (previousPath: string) => () => {
    if (attendTransport !== null) {
      setAttendTransport(null);
    } else if (blessingPlace) {
      setBlessingPlace(null);
    } else if (targetMunicipality) {
      setTargetMunicipality(null);
    } else if (hospital) {
      setHospital(null);
    } else if (shadowHospital) {
      setShadowHospital(null);
    } else if (currentMunicipality) {
      setCurrentMunicipality(null);
      setSkipBlessingPlace(null);
    } else if (previousPath) {
      navigate(previousPath);
    }
  };

  return [
    hospital,
    setHospital,
    shadowHospital,
    setShadowHospital,
    currentMunicipality,
    setCurrentMunicipality,
    targetMunicipality,
    setTargetMunicipality,
    blessingPlace,
    setBlessingPlace,
    skipBlessingPlace,
    setEmptyBlessingPlace,
    attendTransport,
    setAttendTransport,
    undo,
  ] as const;
};

export default useTransportationStore;
