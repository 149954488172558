const copyUnfinishedRequestUrl = (
  data: any,
  callback: (result: any) => void
) => {
  if (!navigator?.clipboard?.write || !navigator?.clipboard?.writeText) {
    callback({ success: false });
    return;
  }

  let headers = new Headers();

  if (process.env.REACT_APP_API_AUTH) {
    headers.set(
      "Authorization",
      "Basic " + btoa(process.env.REACT_APP_API_AUTH)
    );
  }

  headers.set("Content-Type", "application/json");

  const url = [
    process.env.REACT_APP_API_BASE,
    "wp-json",
    "memoria",
    "v1",
    "submit-unfinished-request",
  ].join("/");

  const options = {
    method: "POST",
    headers,
    credentials: "include" as RequestCredentials,
    body: JSON.stringify(data),
  };

  // ref: https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
  if (typeof ClipboardItem) {
    const text = new ClipboardItem({
      "text/plain": fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            callback({ success: true });
            const urlToCopy = `${window.location.origin}/yhteenveto/${result.hash}`;
            return new Blob([urlToCopy], { type: "text/plain" });
          } else {
            return new Blob([""], { type: "text/plain" });
          }
        })
        .then((text) => new Blob([text], { type: "text/plain" })),
    });
    navigator.clipboard.write([text]);
  } else {
    fetch(url, options)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          navigator.clipboard.writeText(
            `${window.location.origin}/yhteenveto/${result.hash}`
          );
          callback({ success: true });
        }
      });
  }
};

export default copyUnfinishedRequestUrl;
