import { useData } from "utils/getData";
import useStore from "utils/useStore";

const useMappedClothingPrices = () => {
  const storedAgency = useStore((state) => state.agency);
  const agency = useData(`agency/${storedAgency}`) as any;
  const clothing = useData("clothing/?_embed");

  if (!Array.isArray(clothing) || !agency) {
    return [];
  }

  const agencyClothings =
    agency && agency.acf ? agency.acf.agency_clothing : null;

  if (!agencyClothings) {
    return [];
  }

  const mappedClothings = agencyClothings
    .map((agencyClothing: any) => {
      const mappedClothing = clothing.find(
        (clothing: any) => clothing.id === agencyClothing.clothing
      );

      if (!mappedClothing) {
        return null;
      }

      mappedClothing.price = agencyClothing.price;

      return mappedClothing;
    })
    .filter((clothing: any) => !!clothing);

  return Array.isArray(mappedClothings) ? mappedClothings : [];
};

export default useMappedClothingPrices;
