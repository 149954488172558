const useScrollToTop = () => {
  const executeScroll = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  };

  return [executeScroll];
};

export default useScrollToTop;
